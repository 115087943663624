import { Button, Col, Row, Tooltip } from "antd";
import styles from "./MachineCard.module.scss"
import { useMemo, useState } from "react";
import { DocumentCategory, MachineDetailModel } from "../../service";
import placeholder from "./../assets/placeholder.svg";
import { t } from "../../translate";
import { activeApiBaseUrl } from "../../store/app-constants";
import {ReactComponent as MapIcon} from "../assets/icons/map-icon.svg"
import { get2SaleMachineMustField } from "../../utils/machine-utils";

type MachineCardProps = {
    cardType: 'rental' | 'sale';
    machine: MachineDetailModel;
    onFooterButtonClicked: () => void;
};

const MachineCard = (props: MachineCardProps) => {
    const [imageError, setImageError] = useState(false);
    const imageSrc = useMemo(() => {
        if (imageError) return placeholder
        const photoId = props.machine?.documents?.filter((docs) => docs.documentCategory === DocumentCategory.Photo).at(0)?.document?.id
        const url = photoId !== undefined ? `${activeApiBaseUrl}/document/machine-document/${photoId}`
        : placeholder;
        return(
            url
        );
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.machine, imageError]
    );
    
    const mustFields = get2SaleMachineMustField(props.machine);
    const toolTipNumber = props.cardType === 'rental' && props.machine.serviceRegions ? props.machine.serviceRegions.length - 1 : -1;
    const toolTipTitle = props.cardType === 'rental' && props.machine.serviceRegions ? props.machine.serviceRegions.length > 1 ? props.machine.serviceRegions.map((e, i) => { if (i > 0) return e.name; else return ""}) : [] : [];
    
    const dailyPrc = useMemo(() => {
        return (
            props.machine.rentalTypes?.filter(e => e.rentalType?.id === 2).at(0)?.price
        );
    }, [props.machine])
    const monthlyPrc = useMemo(() => {
        return (
            props.machine.rentalTypes?.filter(e => e.rentalType?.id === 3).at(0)?.price
        );
    }, [props.machine])
    const showDailyPrice = dailyPrc && dailyPrc > 0;
    const showMonthlyPrice = monthlyPrc && monthlyPrc > 0;
    const formatStr = (str: string) => {
        return str.split(' ')
        .map(w => w[0]?.toUpperCase() + w.substring(1).toLocaleLowerCase('tr-TR'))
        .join(' ')
      }
      
    return (
        <Row className={styles.container}>
                <Row className={styles.titleContainer}>
                    <h4>{props.machine.id + ' (' + t('machines.id') + ')'}</h4>
                </Row>
                <Col xs={24} className={styles.separator} />
                <Row className={styles.contentContainer} gutter={[0, 8]}>
                    <Col xs={8} md={8} className={styles.imgContainer}>
                        <img
                            src={imageSrc}
                            alt=""
                            onError={() => {setImageError(true)}}
                            className={imageSrc === placeholder ? styles.imgPlaceholderStyle : styles.imgStyle}
                        />
                    </Col>
                    <Col xs={16} md={16} className={styles.infoContainer}>
                        <div className={styles.infoInnerContainer}>
                            <Row className={styles.infoPropertyContainer}>
                                <Col xs={8} md={7} className={styles.infoPropertyNameContainer}>
                                    <span>{t('machine-card.category-title') + ':'}</span>
                                </Col>
                                <Col xs={16} md={17} className={styles.infoPropertyValueContainer}>
                                    <h5 className={styles.infoPropertyValueContainerh5}>{props.machine.subCategory?.category?.name}</h5>
                                </Col>
                            </Row>
                            <Row className={styles.infoPropertyContainer}>
                                <Col xs={8} md={7} className={styles.infoPropertyNameContainer}>
                                    <span>{t('machine-card.brand-title') + ':'}</span>
                                </Col>
                                <Col xs={16} md={17} className={styles.infoPropertyValueContainer}>
                                    <h5 className={styles.infoPropertyValueContainerh5}>{formatStr(props.machine.brand?.name ?? "")}</h5>
                                </Col>
                            </Row>
                            <Row className={styles.infoPropertyContainer}>
                                <Col xs={12} md={7} className={styles.infoPropertyNameContainer}>
                                    <span>{t('machine-card.subcategory-title') + ':'}</span>
                                </Col>
                                <Col xs={12} md={17} className={styles.infoPropertyValueContainer}>
                                    <h5 className={styles.infoPropertyValueContainerh5}>{props.machine.subCategory?.name}</h5>
                                </Col>
                            </Row>
                                <Row className={styles.infoPropertyContainer}>
                                    <Col xs={14} className={styles.infoPropertyNameContainer}>
                                        <span>{mustFields.at(0)?.mustFieldLabel}</span>
                                    </Col>
                                    <Col xs={10} className={styles.infoPropertyValueContainer}>
                                        <h5 className={styles.infoPropertyValueContainerh5}>{mustFields.at(0)?.mustFieldValue + ' ' + mustFields.at(0)?.mustFieldUnit}</h5>
                                    </Col>
                                </Row>
                                <>
                                    {
                                        dailyPrc ? 
                                        (
                                        <Row className={styles.infoPropertyContainer} style={!showDailyPrice ? {visibility: "hidden"} : undefined}>
                                            <Col xs={14} className={styles.infoPropertyNameContainer}>
                                                <span>{t('machine-card.daily-title') + (props.machine.hasOperator ? '': ':')}</span>
                                                {   
                                                    props.machine.hasOperator && 
                                                    (<Tooltip
                                                        placement="top"
                                                        title={t("webSite.machineDetailRental.hasOperator")}
                                                        >
                                                        {' '}
                                                    </Tooltip>)
                                                }
                                            </Col>
                                            <Col xs={10} className={styles.infoPropertyValueContainer}>
                                                <h5 className={styles.infoPropertyValueContainerh5}>{
                                                    (Math.round(dailyPrc).toLocaleString('tr') + (' ₺'))
                                                }</h5>
                                            </Col>
                                        </Row> 
                                        ) :( <></>)
                                    }
                                    {
                                        monthlyPrc ? 
                                        (
                                            <Row className={styles.infoPropertyContainer} style={!showMonthlyPrice ? {visibility: "hidden"} : undefined}>
                                                <Col xs={14} className={styles.infoPropertyNameContainer}>
                                                    <span>{t('machine-card.monthly-title') + (props.machine.hasOperator ? '': ':')}</span>
                                                    {   
                                                        props.machine.hasOperator && 
                                                        (<Tooltip
                                                            placement="top"
                                                            title={t("webSite.machineDetailRental.hasOperator")}
                                                            >
                                                            {' '}
                                                        </Tooltip>)
                                                    }
                                                </Col>
                                                <Col xs={10} className={styles.infoPropertyValueContainer}>
                                                    <h5 className={styles.infoPropertyValueContainerh5}>{
                                                        (Math.round(monthlyPrc).toLocaleString('tr') + (' ₺'))
                                                    }</h5>
                                                </Col>
                                            </Row>
                                        ): (<></>)
                                    }
                                </>
                        </div>
                    </Col>
                </Row>
                <Col xs={24} className={styles.separator} />
            <Row className={styles.cardFooterContainer}>
                <Col xs={13} md={14} className={styles.locationContainer}>
                    <Row className={styles.locationInnerContainer} gutter={[8, 0]}>
                        <Col xs={4} md={2} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
                            <MapIcon width={20} height={20} />
                        </Col>
                        <Col xs={20} md={22} className={styles.locationTextContainer}>
                            <span>{(props.machine.serviceRegions && props.machine.serviceRegions.length > 0 ? props.machine.serviceRegions[0].name : '')}</span>
                            {
                            props.cardType === 'rental' && toolTipNumber > 0 &&
                            (
                                <Tooltip  placement="bottom" overlayInnerStyle={{
                                    background: "#FFFFFF",
                                    color: "#949394",
                                    maxHeight: "410px", 
                                    overflowY: "auto",  
                                  }} title={toolTipTitle.map((e, i) => {
                                    return (<span>{e}{i !== 0 ? <br /> : ''}</span>);
                                })}>
                                    <Col xs={8} md={3} className={styles.locationTooltipContainer}>
                                        <span>{'+' + toolTipNumber}</span>
                                    </Col>
                                </Tooltip>
                            )
                        }
                        </Col>
                    </Row>
                </Col>
                <Col xs={11} md={10} className={styles.cardFooterButtonContainer}>
                    <Button onClick={() => {props.onFooterButtonClicked()}} className={styles.footerButton}>
                        <span className={styles.buttonText}>{t('machine-card.change-machine')}</span>
                    </Button>
                </Col>
            </Row>
        </Row>
    );
}


export default MachineCard;