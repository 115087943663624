import { Layout, List } from "antd";
import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import SecureLayout from "../../components/layouts/secure-layout";
import { NotificationDetailModel } from "../../service";
import { userAtom } from "../../store/global-atoms";
import { useSecureService } from "../../store/service-atoms";
import { Empty } from "antd";
import { t } from "../../translate";

const Notifications = () => {
    const { doGetAllNotifications } = useSecureService();
    const [notifications, setNotifications] = useState<NotificationDetailModel[]>();
    const [user] = useAtom(userAtom);
    const navigate = useNavigate();

    useEffect(() => {
        const doGetNotifications = async () => {
            await doGetAllNotifications(user?.id).then((result) => {
                setNotifications(result);
            });
        };
        doGetNotifications();
        // eslint-disable-next-line
    }, []);

    return (
        <SecureLayout activePage={"notifications"} title={t("notifications.title")} description={t("notifications.description")}>
            <Layout style={{ padding: 15, backgroundColor: "white", borderRadius: 20 }}>
                <List
                    itemLayout="horizontal"
                    dataSource={notifications}
                    locale={{emptyText: <Empty description={t('notification.empty')} />}}
                    renderItem={(item) => (
                        <List.Item>
                            <List.Item.Meta
                                description={
                                    <div
                                        style={{cursor: "pointer"}}
                                        onClick={() => {
                                            if (item.receiverRole === "Renter") {
                                                navigate("/requests/view?reqid=" + item.requestId);
                                            } else if (item.receiverRole === "Lessor") {
                                                if (item.from === "Machine") {
                                                    navigate("/machines/view?macid=" + item.machineId); 
                                                    console.log("from mac",item.from)
                                                }
                                                 if(item.from === "Offer"){
                                                     navigate("/offers/view?reqid=" + item.requestId + "&offerid=" + item.offerId);
                                                     console.log("from off",item.from)
                                                 }
                                                if(item.status === "Suggested") {
                                                    navigate("/requests/view?reqid=" + item.requestId + "&offerid=" + item.offerId);                                                    
                                                }
                                            }
                                        }}
                                    > 
                                        {item.text}
                                    </div>
                                }
                            />
                        </List.Item>
                    )}
                />
            </Layout>
        </SecureLayout>
    );
};

export default Notifications;
