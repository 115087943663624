import OnboardLayout from "./onboard-layout";
import styles from "../styles/auth-layout.module.scss";

export type LoginLayoutProps = {
    children: any;
    smallLogo?: boolean;
    hideMachineLogo?: boolean;
    hideBackgroundImg?: boolean;
    hideUpperLogo?: boolean;
};

const AuthLayout = (props: LoginLayoutProps) => {
    return (
        <OnboardLayout hideLogo={props.hideUpperLogo} hideBackgroundImg={props.hideBackgroundImg} footerVisible={true} smallLogo={props.smallLogo}>
            <div className={styles.formColumnContainer}>{props.children}</div>
        </OnboardLayout>
    );
};

export default AuthLayout;
