import { Pagination, Row } from "antd";
import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PrimaryButton from "../../../components/atomics/primary-button";
import RequestListCard from "../../../components/atomics/request-list-card";
import SecureLayout from "../../../components/layouts/secure-layout";
import { OfferFormSearchModel, OfferFormViewModel } from "../../../service";
import { loadingAtom } from "../../../store/global-atoms";
import { useSecureService } from "../../../store/service-atoms";
import { t } from "../../../translate";

const ListRequests = () => {
    const navigate = useNavigate();
    const [totalItems, setTotalItems] = useState<number | undefined>(0);
    const [activePage, setActivePage] = useState<number | undefined>(1);
    const { doSearchOffers } = useSecureService();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [loading] = useAtom(loadingAtom);
    const [filterObj] = useState<OfferFormSearchModel>({});
    const [offers, setOffers] = useState<OfferFormViewModel[]>([]);
    const [dataFetching, setDataFetching] = useState(true);
    const pageSize = 6;
    useEffect(() => {
        const getRequests = async () => {
            setDataFetching(true);
            const result = await doSearchOffers({
                pageNo: activePage,
                pageSize: pageSize,
                ...filterObj,
            });
            if (result && result.data) {
                setOffers(result.data);
                setActivePage(result.pageNo);
                setTotalItems(result.itemCount);
            }
            setDataFetching(false);
        };
        getRequests();
        //eslint-disable-next-line
    }, [activePage]);

    const pageChange = (page: number) => {
        setActivePage(page);
    };

    const viewClick = (reqId: number | undefined) => {
        const offer = offers.find((off) => off.requestForm?.id === reqId);
        navigate("/requests/view?reqid=" + reqId + "&offerid=" + offer?.id);
    };

    return (
        <SecureLayout
            activePage={"requests"}
            loading={dataFetching}
            role={"lessor"}
            title={t("requests.list.title")}
            description={t("requests.list.description")}
            helperButtons={[<PrimaryButton >{t("common.search")}</PrimaryButton>]}
        >
            <Row gutter={[16, 16]} style={{ marginBottom: "2rem" }}>
                {offers.map((x) => {
                    return x.requestForm === null || x.requestForm === undefined ? (
                        <></>
                    ) : (
                        <RequestListCard
                            role="lessor"
                            onViewClick={(id) => viewClick(id)}
                            xs={24}
                            md={8}
                            requestForm={x.requestForm}
                            offerStatus={x.status}
                        />
                    );
                })}
            </Row>
            <Row style={{ justifyContent: "center" }}>
                <Pagination defaultCurrent={activePage} responsive current={activePage} pageSize={pageSize} total={totalItems} onChange={pageChange} />
            </Row>
        </SecureLayout>
    );
};

export default ListRequests;
