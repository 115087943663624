/* tslint:disable */
/* eslint-disable */
/**
 * MachineGo P1 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const LoginMethodType = {
    Email: 'Email',
    Google: 'Google',
    Facebook: 'Facebook',
    Gms: 'Gms'
} as const;
export type LoginMethodType = typeof LoginMethodType[keyof typeof LoginMethodType];


export function LoginMethodTypeFromJSON(json: any): LoginMethodType {
    return LoginMethodTypeFromJSONTyped(json, false);
}

export function LoginMethodTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): LoginMethodType {
    return json as LoginMethodType;
}

export function LoginMethodTypeToJSON(value?: LoginMethodType | null): any {
    return value as any;
}

