import { Form } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { t } from "../../translate";
import { ReactComponent as PhoneIcon } from "../../components/assets/phoneIcon.svg";
import { useAtom } from "jotai";
import { signupServiceErrorAtom } from "../../store/global-atoms";
import { MaskedInput } from "antd-mask-input";

export type UsernameInputProps = {
    prefix: boolean;
    initialValue?: string;
    type?: "phone" | "other";
    className?: string;
    style?: React.CSSProperties;
    placeholder?: string;
};

const UsernameInput = (props: UsernameInputProps) => {
    const [signUpServiceError, setSignUpServiceError] = useAtom(signupServiceErrorAtom);
    return (
        <Form.Item
            name="phone"
            label={t("login.username.label")}
            dependencies={signUpServiceError ? [[signUpServiceError]] : undefined}
            rules={[
                {
                    message: t("signup.form.phoneValidation"),
                    required: true,
                },
                {
                    pattern: /^\+90\(\d{3}\)\d{3}-\d{4}$/,
                    message: t("signup.form.phoneValidation"),
                },
                ({ getFieldValue }) => ({
                    validator(_, value) {
                        if (signUpServiceError === "usedPhone") {
                            return Promise.reject(new Error(t("signup.form.phoneIsUsedValidationError")));
                        } else {
                            return Promise.resolve();
                        }
                    },
                }),
            ]}
        >
            <MaskedInput
                mask={"+90(000)000-0000"}
                prefix={
                    props.prefix ? props.type === "phone" ? <PhoneIcon /> : <UserOutlined className="site-form-item-icon" /> : undefined
                }
                style={props.style ? props.style : { width: "100%" }}
                type={"tel"}
                onChange={(e) => {
                    if (signUpServiceError === "usedPhone") {
                        setSignUpServiceError(null);
                    }
                    const maskedValue = e.target.value; // Kullanıcının girdiği maskeli değer
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    const unmaskedValue = maskedValue.replace(/\D/g, ""); // Maskeyi kaldırarak sadece sayıları alır
                }}
                placeholder={props.placeholder ?? (props.type === "phone" ? "+90" : "")}
                defaultValue={"+90"}
                className={props.className ? props.className : undefined}
            />
        </Form.Item>
    );
};

export default UsernameInput;
