/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useRef } from "react";
import { useSecureService } from "../store/service-atoms";
import { UserSessionHeartbeatPostRequest } from "../service";

export const useUserTracking = (user: any, onLogout: () => void) => {
    const [isAfk, setIsAfk] = useState(false);
    const [afkStartTime, setAfkStartTime] = useState<Date | null>(null);
    const [lastActive, setLastActive] = useState<Date>(new Date());
    const afkTimer = useRef<NodeJS.Timeout | null>(null);
    const heartbeatTimer = useRef<NodeJS.Timeout | null>(null);
    const afkLogoutTimer = useRef<NodeJS.Timeout | null>(null);
    const { doHeartBeat } = useSecureService();

    const [heartbeatRequest, setHeartbeatRequest] = useState<UserSessionHeartbeatPostRequest>({
        userId: undefined,
        isLessor: undefined,
        isAfk: false,
        afkStartTime: undefined,
    });

    useEffect(() => {
        if (user) {
            setHeartbeatRequest({
                userId: user.id,
                isLessor: user?.role === "Lessor",
                isAfk,
                afkStartTime: isAfk ? (afkStartTime as Date) : undefined,
            });
        }
    }, [user, isAfk, afkStartTime]);

    const startAfk = () => {
        setIsAfk(true);
        setAfkStartTime(new Date());

        // Kullanıcı 15 dakika AFK kalırsa logout yap
        afkLogoutTimer.current = setTimeout(() => {
            onLogout();
        }, 15 * 60 * 1000); // 15 dakika
    };

    const stopAfk = () => {
        setIsAfk(false);
        setAfkStartTime(null);
        setLastActive(new Date());

        // Logout timer'ı durdur
        if (afkLogoutTimer.current) {
            clearTimeout(afkLogoutTimer.current);
        }
    };

    const sendHeartbeat = async () => {
        await doHeartBeat(heartbeatRequest);
    };

    const resetAfkTimer = () => {
        if (isAfk) {
            stopAfk();
        }
        setLastActive(new Date());
        if (afkTimer.current) clearTimeout(afkTimer.current);

        afkTimer.current = setTimeout(() => {
            startAfk();
        }, 10000); // 10 saniye
    };

    useEffect(() => {
        window.addEventListener("mousemove", resetAfkTimer);
        window.addEventListener("keydown", resetAfkTimer);
        window.addEventListener("scroll", resetAfkTimer);

        return () => {
            window.removeEventListener("mousemove", resetAfkTimer);
            window.removeEventListener("keydown", resetAfkTimer);
            window.removeEventListener("scroll", resetAfkTimer);
        };
    }, [isAfk, lastActive]);

    useEffect(() => {
        heartbeatTimer.current = setInterval(sendHeartbeat, 5000); // 5 saniyede bir heartbeat gönderimi
        return () => {
            if (heartbeatTimer.current) clearInterval(heartbeatTimer.current);
        };
    }, [heartbeatRequest]);

    return { isAfk, afkStartTime };
};
