import { Content, Header } from "antd/lib/layout/layout";
import { ReactElement } from "react";
import BaseLayout from "./base-layout";
import { ReactComponent as Logo } from "../assets/logo.svg";
import styles from "../styles/onboard-layout.module.scss";
import logoWhite from "../assets/logo.svg";
import { activeRootSiteUrl } from "../../store/app-constants";
import { Row, Col, Typography, Card, Layout, Space } from "antd";
import HeadphonesIcon from "../assets/icons/HeadphonesIcon.png";
import PhoneMobileIcon from "../assets/icons/Phone.png";
import OpenMailIcon from "../assets/icons/OpenMailIcon.png";
import FacebookIcon from "../assets/icons/facebook.png";
import TwitterIcon from "../assets/icons/twitter.png";
import InstagramIcon from "../assets/icons/instagram.png";
import LinkedInIcon from "../assets/icons/linkedin.png";

export type OnboardLayoutProps = {
    children: any;
    header?: string | ReactElement;
    smallLogo?: boolean;
    h2?: string | ReactElement;
    footerVisible?: boolean;
    hideLogo?: boolean;
    hideBackgroundImg?: boolean;
};

const OnboardLayout = (props: OnboardLayoutProps) => {
    const logo = props.smallLogo ? (
        <Logo
            height={"2.5rem"}
            onClick={() => {
                window.location.replace(activeRootSiteUrl);
            }}
        />
    ) : (
        <Logo
            onClick={() => {
                window.location.replace(activeRootSiteUrl);
            }}
        />
    );

    const baseUrl = activeRootSiteUrl;

    const vehicles = [
        {
            id: 1,
            name: "Ekskavatör",
            urlRental: "/kiralik-is-makineleri/ekskavatorler-kazici",
            urlSale: "/satilik-is-makineleri/ekskavatorler-kazici",
        },
        {
            id: 4,
            name: "Beko Loder",
            urlRental: "/kiralik-is-makineleri/beko-loder-kazici-yukleyici",
            urlSale: "/satilik-is-makineleri/beko-loder-kazici-yukleyici",
        },
        { id: 37, name: "Forklift", urlRental: "/kiralik-is-makineleri/forklift", urlSale: "/satilik-is-makineleri/forklift" },
        {
            id: 27,
            name: "Hiyap",
            urlRental: "/kiralik-is-makineleri/hiyap-kamyon-ustu-vinc",
            urlSale: "/satilik-is-makineleri/hiyap-kamyon-ustu-vinc",
        },
        { id: 10, name: "Kamyon", urlRental: "/kiralik-is-makineleri/kamyon", urlSale: "/satilik-is-makineleri/kamyon" },
        {
            id: 2,
            name: "Mini Ekskavatör",
            urlRental: "/kiralik-is-makineleri/mini-ekskavatorler-mini-kazici",
            urlSale: "/satilik-is-makineleri/mini-ekskavatorler-mini-kazici",
        },
    ];

    return (
        <BaseLayout>
            <Header className={styles.header}>
                {props.hideLogo ? <></> : logo}
                {props.header && <h1>{props.header}</h1>}
                {props.h2 && <h2 className={styles.signupHeaderOne}>{props.h2}</h2>}
            </Header>
            <Content className={styles.content} style={props.hideBackgroundImg ? { backgroundImage: "none" } : undefined}>
                {props.children}
            </Content>
            {props.footerVisible && (
                <footer className={styles.footerWrapper}>
                    <div className={styles.footerContainer}>
                        <div className={styles.footerDesktop}>
                            <Row className={styles.footerContent} justify="center">
                                <Col xs={24} sm={24} md={8} lg={6} xl={6} className={styles.footerLeft}>
                                    <img src={logoWhite} alt="Logo" width={193} height={52} />
                                    <p>
                                        Projeleriniz için ihtiyacınız olan iş ve endüstri makineleri MachineGo'da! İster kiralayın, ister
                                        alın, ister satın. Türkiye'nin en geniş makine yelpazesine sahip dijital platformu MachineGo ile
                                        zaman kazanın, maliyetlerinizi düşürün ve işlerinizi kolaylaştırın.
                                    </p>
                                    <Row>
                                        <a
                                            className={styles.socialMediaLinks}
                                            href="https://www.facebook.com/machinegocom/"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <img src={FacebookIcon} alt="Logo" />
                                        </a>
                                        <a
                                            className={styles.socialMediaLinks}
                                            href="https://www.linkedin.com/company/machinego/"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <img src={LinkedInIcon} alt="Logo" />
                                        </a>
                                        <a
                                            className={styles.socialMediaLinks}
                                            href="https://www.instagram.com/machinegocom/"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <img src={InstagramIcon} alt="Logo" />
                                        </a>
                                        <a
                                            className={styles.socialMediaLinks}
                                            href="https://x.com/machinegocom"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <img src={TwitterIcon} width={32} alt="Logo" />
                                        </a>
                                    </Row>
                                </Col>

                                <Col xs={24} sm={24} md={16} lg={16} xl={12} className={styles.footerLink}>
                                    <ul>
                                        <li>
                                            <a className={styles.titleLink}  href={`${baseUrl}/tr/#hakkimizda`}>
                                                Hakkımızda
                                            </a>
                                        </li>
                                        <li>
                                            <a className={styles.titleLink}  href={`${baseUrl}/tr/blog`}>
                                                Blog
                                            </a>
                                        </li>
                                        <li>
                                            <Typography className={styles.titleLink}>Sözleşmeler</Typography>
                                        </li>
                                        <li>
                                            <a href={`${baseUrl}/tr/aydinlatma-metni`}>
                                                Gizlilik Ve Kişisel Verilerin Korunması Politikası
                                            </a>
                                        </li>
                                        <li>
                                            <a href={`${baseUrl}/tr/kullanici-sozlesmesi`}>Kullanıcı Sözleşmesi</a>
                                        </li>
                                        <li>
                                            <a href={`${baseUrl}/tr/kurumsal-ve-bireysel-uye-acik-riza-metni`}>
                                                Kurumsal Ve Bireysel Üye Açık Rıza Metni
                                            </a>
                                        </li>
                                        <li>
                                            <a href={`${baseUrl}/tr/izinli-pazarlama-onay-metni`}>İzinli Pazarlama Onay Metni</a>
                                        </li>
                                    </ul>

                                    <ul>
                                        <li>
                                            <Typography className={styles.titleLink}>KİRALIK</Typography>
                                        </li>
                                        {vehicles.map((vehicle) => {
                                            return (
                                                <li key={vehicle.id}>
                                                    <a href={`${baseUrl}/tr/${vehicle.urlRental}`} target={"_blank"} rel="noreferrer">
                                                        {vehicle.name}
                                                    </a>
                                                </li>
                                            );
                                        })}
                                    </ul>

                                    <ul>
                                        <li>
                                            <Typography className={styles.titleLink}>SATILIK</Typography>
                                        </li>
                                        {vehicles.map((vehicle) => {
                                            return (
                                                <li key={vehicle.id}>
                                                    <a href={`${baseUrl}/tr/${vehicle.urlSale}`} target={"_blank"} rel="noreferrer">
                                                        {vehicle.name}
                                                    </a>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </Col>

                                <Col
                                    xs={24}
                                    sm={24}
                                    md={24}
                                    lg={24}
                                    xl={6}
                                    className={styles.footerLink}
                                    style={{ justifyContent: "center !important" }}
                                >
                                    <Row gutter={[16, 16]} align="top">
                                        <Col xs={24} sm={24} md={12} lg={12} xl={24}>
                                            <Card className={styles.footerCard} style={{ width: "100%", maxWidth: 300, height: 140 }}>
                                                <div className={styles.cardContent}>
                                                    <img src={HeadphonesIcon} width={32} height={32} alt="icon" />
                                                    <p>
                                                        <span className={styles.machingoColor}>MachingeGo</span> İletişim Numarası
                                                    </p>
                                                    <div>
                                                        <a className={styles.machingoLink} href={"tel:0212 706 0330"}>
                                                            <Row className={styles.valueContainer}>
                                                                <span>+90 212 706 0330</span>
                                                            </Row>
                                                        </a>
                                                    </div>
                                                </div>
                                            </Card>
                                        </Col>

                                        <Col xs={24} sm={24} md={12} lg={12} xl={24}>
                                            <Card className={styles.footerCard} style={{ width: "100%", maxWidth: 300, height: 140 }}>
                                                <div className={styles.cardContent}>
                                                    <img src={OpenMailIcon} width={32} height={32} alt="icon" />
                                                    <p>
                                                        <span>
                                                            <span className={styles.machingoColor}>MachineGo </span>
                                                            Destek Maili
                                                        </span>
                                                    </p>
                                                    <div>
                                                        <a className={styles.machingoLink} href={"mailto:helpdesk@machinego.com"}>
                                                            <Row className={styles.valueContainer}>
                                                                <span>helpdesk@machinego.com</span>
                                                            </Row>
                                                        </a>
                                                    </div>
                                                </div>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>

                        <Layout.Footer className={styles.footerMobile} style={{ backgroundColor: "#f7f7f7", padding: "30px 1rem" }}>
                            <Row justify="center" style={{ margin: "10px 0" }}>
                                <Col xs={24} sm={24} md={18} lg={16}>
                                    <img src={logoWhite} alt="Logo" style={{ marginLeft: "1vw" }} width={142} height={38} />
                                </Col>

                                <Col xs={24} sm={24} md={18} lg={16}>
                                    <p>Projeleriniz için ihtiyacınız olan iş ve endüstri makineleri MachineGo'da! İster kiralayın, ister alın, ister satın. Türkiye'nin en geniş makine yelpazesine sahip dijital platformu MachineGo ile zaman kazanın, maliyetlerinizi düşürün ve işlerinizi kolaylaştırın.</p>
                                </Col>

                                <Col xs={24} sm={24} md={18} lg={16} className={styles.mobileCallLink}>
                                    <img src={PhoneMobileIcon} alt="Mobile icon" />
                                    <a href="tel:0212 706 0330">0 (212) 706 0330</a>
                                </Col>
                            </Row>

                            <Row justify="center" gutter={[16, 16]}>
                                <Col xs={12} sm={12} md={8} lg={8} className={styles.footerLink}>
                                    <ul style={{ padding: 0, margin: 0, listStyleType: "none" }}>
                                        <li>
                                            <a className={styles.titleLink}  href={`${baseUrl}/tr/#hakkimizda`}>
                                                Hakkımızda
                                            </a>
                                        </li>
                                        <li>
                                            <a className={styles.titleLink} href={`${baseUrl}/tr/blog`}>
                                                Blog
                                            </a>
                                        </li>
                                    </ul>
                                </Col>

                                <Col xs={12} sm={12} md={8} lg={8} className={styles.footerLink}>
                                    <ul style={{ padding: 0, margin: 0, listStyleType: "none" }}>
                                        <li>
                                            <Typography className={styles.titleLink}>Sözleşmeler</Typography>
                                        </li>
                                        <li>
                                            <a href={`${baseUrl}/tr/aydinlatma-metni`}>
                                                Gizlilik Ve Kişisel Verilerin Korunması Politikası
                                            </a>
                                        </li>
                                        <li>
                                            <a href={`${baseUrl}/tr/kullanici-sozlesmesi`}>Kullanıcı Sözleşmesi</a>
                                        </li>
                                        <li>
                                            <a href={`${baseUrl}/tr/kurumsal-ve-bireysel-uye-acik-riza-metni`}>
                                                Kurumsal Ve Bireysel Üye Açık Rıza Metni
                                            </a>
                                        </li>
                                        <li>
                                            <a href={`${baseUrl}/tr/izinli-pazarlama-onay-metni`}>İzinli Pazarlama Onay Metni</a>
                                        </li>
                                    </ul>
                                </Col>
                            </Row>

                            <Row justify="center" gutter={[16, 16]} style={{ marginTop: "10px" }}>
                                <Col xs={12} sm={12} md={8} lg={8} className={styles.footerLink}>
                                    <ul style={{ padding: 0, margin: 0, listStyleType: "none" }}>
                                        <li>
                                            <Typography className={styles.titleLink}>KİRALIK</Typography>
                                        </li>
                                        {vehicles.map((vehicle) => {
                                            return (
                                                <li key={vehicle.id}>
                                                    <a href={`${baseUrl}/tr/${vehicle.urlRental}`} target={"_blank"} rel="noreferrer">
                                                        {vehicle.name}
                                                    </a>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </Col>

                                <Col xs={12} sm={12} md={8} lg={8} className={styles.footerLink}>
                                    <ul style={{ padding: 0, margin: 0, listStyleType: "none" }}>
                                        <li>
                                            <Typography className={styles.titleLink}>SATILIK</Typography>
                                        </li>
                                        {vehicles.map((vehicle) => {
                                            return (
                                                <li key={vehicle.id}>
                                                    <a href={`${baseUrl}/tr/${vehicle.urlSale}`} target={"_blank"} rel="noreferrer">
                                                        {vehicle.name}
                                                    </a>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </Col>
                            </Row>

                            <Row justify="center" style={{ margin: "5px 0" }}>
                                <Col>
                                    <Space size="small">
                                        <a className={styles.socialMediaLinks} href="https://www.facebook.com/machinegocom/" target="_blank" rel="noreferrer">
                                            <img src={FacebookIcon} alt="Logo" />
                                        </a>
                                        <a className={styles.socialMediaLinks} href="https://www.linkedin.com/company/machinego/" target="_blank" rel="noreferrer">
                                            <img src={LinkedInIcon} alt="Logo" />
                                        </a>
                                        <a className={styles.socialMediaLinks} href="https://www.instagram.com/machinegocom/" target="_blank" rel="noreferrer">
                                            <img src={InstagramIcon} alt="Logo" />
                                        </a>
                                        <a className={styles.socialMediaLinks} href="https://x.com/machinegocom" target="_blank" rel="noreferrer">
                                            <img src={TwitterIcon} width={32} alt="Logo" />
                                        </a>
                                    </Space>
                                </Col>
                            </Row>
                        </Layout.Footer>
                    </div>
                    <Row>
                        <Col xs={24} md={24} className={styles.footerCopyright}>
                            <p>{new Date().getFullYear()} © Machinego. Tüm Hakları Saklıdır.</p>
                        </Col>
                    </Row>
                </footer>
            )}
        </BaseLayout>
    );
};

export default OnboardLayout;
