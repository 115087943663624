/* tslint:disable */
/* eslint-disable */
/**
 * MachineGo P1 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    UserDeviceInformation,
    UserDeviceInformationFromJSON,
    UserDeviceInformationFromJSONTyped,
    UserDeviceInformationToJSON,
} from './UserDeviceInformation';

/**
 * 
 * @export
 * @interface LoginRequestModel
 */
export interface LoginRequestModel {
    /**
     * 
     * @type {string}
     * @memberof LoginRequestModel
     */
    username: string;
    /**
     * 
     * @type {string}
     * @memberof LoginRequestModel
     */
    password: string;
    /**
     * 
     * @type {UserDeviceInformation}
     * @memberof LoginRequestModel
     */
    userDeviceInformation?: UserDeviceInformation;
}

export function LoginRequestModelFromJSON(json: any): LoginRequestModel {
    return LoginRequestModelFromJSONTyped(json, false);
}

export function LoginRequestModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): LoginRequestModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'username': json['username'],
        'password': json['password'],
        'userDeviceInformation': !exists(json, 'userDeviceInformation') ? undefined : UserDeviceInformationFromJSON(json['userDeviceInformation']),
    };
}

export function LoginRequestModelToJSON(value?: LoginRequestModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'username': value.username,
        'password': value.password,
        'userDeviceInformation': UserDeviceInformationToJSON(value.userDeviceInformation),
    };
}

