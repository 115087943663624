import { Checkbox, Col, Collapse, DatePicker, Form, Input, Row, Select, Spin } from "antd";
import { useNavigate } from "react-router-dom";
import PrimaryButton from "../../components/atomics/primary-button";
import OnboardLayout from "../../components/layouts/onboard-layout";
import { t, trLocale } from "../../translate";
import { postloginMessageAtom, signUpAtom } from "../../store/global-atoms";
import { useAtom } from "jotai";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import {
    AuthType,
    BasicModel,
    CategoryModel,
    CityModel,
    MachineDetailModel,
    RenterCompanyCreateModel,
    RequestFormCreateWithRegisterModel,
    SaleRequestFormCreateWithRegisterModel,
    SocialAuthPageType,
    SocialLoginMessageType,
    SubCategoryModel,
} from "../../service";
import { useSecureService } from "../../store/service-atoms";
import TextArea from "antd/lib/input/TextArea";
import { RangeValue } from "rc-picker/lib/interface";
import moment from "moment";
import UsernameInput from "../../components/atomics/username-input";
import LinkButton from "../../components/atomics/link-button";
import { activeRootSiteUrl, facebookLoginActive, googleLoginActive, redirectRegisterAnonymPageUri } from "../../store/app-constants";
import { agreementsVersions } from "../../store/agreements-versions";
import ModalLayout from "../../components/layouts/modal-layout";
import { useQuery } from "../../helpers/use-query";
import AgreementReadModal from "../../modals/agreement-read-modal";
import SocialAuthButton from "../../components/atomics/social-auth-button";
import { facebookLogin, googleLogin } from "../../utils/auth-utils";
import styles from "./anonym-create-request.module.scss"
import Segmented from "../../components/atomics/segmented";
import { ReactComponent as InfoIcon } from "../../components/assets/icons/info-icon.svg";
import { ReactComponent as TransparentInfoIcon } from "../../components/assets/icons/transparent-info-icon.svg";
import MachineCategorySelect from "../../components/atomics/machine-category-select";
import MachineSubcategorySelect from "../../components/atomics/machine-subcategory-select";
import IncrementsInputVersion2 from "../../components/atomics/increments-input-version-2";
import CollapsePanel from "antd/lib/collapse/CollapsePanel";
import ContactMachinego from "../../page-parts/contact-machinego";
import MachineCard from "../../components/atomics/MachineCard";
import MinMaxCollapseInput from "../../components/atomics/min-max-collapse-input";
import { get2SaleMachineMustField, getMachineSubcategoryFilteredLandingUrl, MustFieldNameAndUnitType } from "../../utils/machine-utils";
import MinMaxInput from "../../components/atomics/min-max-input";
import { ValidateErrorEntity } from "rc-field-form/lib/interface";

const { Option } = Select;

const AnonymRequest = () => {
    const {
        doGetMachineSubcategory,
        doGetExactCities,
        doRenterRegisterWithRequest,
        doGetMachine,
        doSocialLogin,
        doGetFuelTypes,
        doRenterRegisterWithSaleRequest
    } = useSecureService();
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const query = useQuery();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const sid = query.get("sid");
    const macid = query.get("macid");
    const [allMachineCategory, setAllMachineCategories] = useState<CategoryModel[] | undefined>(undefined);
    const [selectedMachineCategory, setSelectedMachineCategory] = useState<CategoryModel | undefined>(undefined);
    const [selectedSubcategoryCategory, setSelectedSubcategoryCategory] = useState<SubCategoryModel | undefined>(undefined);
    const [createCount, setCreateCount] = useState<number | undefined>(undefined);
    const [otherSubCategoryText, setOtherSubCategoryText] = useState<string | undefined>(undefined);
    const [detailText, setDetailText] = useState<string | undefined>(undefined);
    const [form] = Form.useForm();
    const [selectedDistrict, setDistrict] = useState<string | undefined>(undefined);
    const [districts, setDistrictsDropdown] = useState<BasicModel[] | undefined>(undefined);
    const [allCities, setAllCities] = useState<CityModel[] | undefined>(undefined);
    const [selectedDates, setSelectedDates] = useState<RangeValue<moment.Moment>>();
    const mediaQueryList = window.matchMedia("(max-width: 658px)");
    const [hasOtp, setHasOtp] = useState<boolean>(false);
    const [, setErrorMessage] = useAtom(postloginMessageAtom);
    const [showAlreadyRegisteredModal, setShowAlreadyRegisteredModal] = useState<boolean>(false);
    const [showReadAgreementModal, setShowReadAgreementModal] = useState<boolean>(false);
    const [selectedAgreementSrc, setSelectedAgreementSrc] = useState<string>();
    const [selectedAgreementTitle, setSelectedAgreementTitle] = useState<string>();
    const [machineAllDetail, setMachineAllDetail] = useState<MachineDetailModel>();
    const [showRedirectModal, setShowRedirectModal] = useState<boolean>(false);
    const [signUp, setSignUp] = useAtom(signUpAtom);
    const [,setWarningMessage] = useAtom(postloginMessageAtom);
    const createReqSocialCounterRef = useRef(0);
    const [selectedCity, setCity] = useState<
        | {
              id?: number | null;
              name?: string | null;
              districts?: BasicModel[] | null;
          }
        | undefined
    >(undefined);
    const [selectedRequestType, setselectedRequestType] = useState<'rental' | 'sale'>(query.get("reqType") === 'sale' ? 'sale' : 'rental');
    const [, setUserPhone] = useState<string>();
    const socialLoginQuery = query.get("state")?.split('loginType:')[1].split('&')[0];
    const requestTypeQuery = query.get("state")?.split('reqType:')[1] === 'rental' ? 'rental' : 'sale';
    const socialLoginType = (socialLoginQuery as AuthType) || null; // code injected by facebook login dialog
    const socialLoginCode = query.get("code"); // code injected by facebook login dialog
    const [socialLoginLoading, setSocialLoginLoading] = useState(query.get("code") !== null);
    const [showRequestSuccessModal, setShowRequestSuccessModal] = useState<boolean>(false);
    const [saleMachineMustFields, setSaleMachineMustFields] = useState<MustFieldNameAndUnitType[]>([]);
    const [fuelTypes, setFuelTypes] = useState<BasicModel[] | undefined>(undefined);
    const additionalValidationNames = useRef<string[]>([]);

    useEffect(() => {
        const getFuelTypes = async () => {
            const response = await doGetFuelTypes();
            if (response) {
                setFuelTypes(response);
            }
        };
        getFuelTypes();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        const getMachineDetails = async () => {
            if (macid) {
                const macidN = parseInt(macid);
                if (!Number.isNaN(macid)) {
                    const result = await doGetMachine(macidN, true);
                    if (result) {
                        setMachineAllDetail(result);
                    }
                }
            }
        };
        getMachineDetails();
        //eslint-disable-next-line
    }, []);

    const setSocialRequestAtomWithoutEmail = async (socialType: AuthType): Promise<boolean> => {
        const formRequest = await getRequestFormValues(form.getFieldsValue());
        const saleFormRequest = await getSaleRequestFormValues(form.getFieldsValue());
        if (selectedRequestType === 'rental' && formRequest !== undefined) {
            setSignUp({
                authType: socialType,
                email: "",
                request: {
                    ...formRequest,
                    withRegister: true,
                    renterUser: {
                        phone:"",
                        sendActivationOTP: true,
                        authType: socialType,
                        agreementVersion:  agreementsVersions.agreementVersion,
                        kvkkVersion: agreementsVersions.kvkkVersion,
                        ipazVersion: form.getFieldValue('ipazVersion') ? agreementsVersions.ipazVersion : null,
                    }
                },
                saleRequest: undefined,
            });
        } else if (selectedRequestType === 'sale' && saleFormRequest !== undefined) {
            setSignUp({
                authType: socialType,
                email: "",
                saleRequest: {
                    ...saleFormRequest,
                    withRegister: true,
                    renterUser: {
                        phone:"",
                        sendActivationOTP: true,
                        authType: socialType,
                        agreementVersion:  agreementsVersions.agreementVersion,
                        kvkkVersion: agreementsVersions.kvkkVersion,
                        ipazVersion: form.getFieldValue('ipazVersion') ? agreementsVersions.ipazVersion : null,
                    }
                },
                request: undefined,
            });
        } else {
            return false;
        }
        return true;
    }

    useEffect(() => {
        const socialLoginAsync = async (authType: AuthType) => {
            createReqSocialCounterRef.current++;
            var res = await doSocialLogin("customer", {authType: authType, socialToken: socialLoginCode, socialAuthPageType: SocialAuthPageType.Anonymregister});
            switch(res?.type) {
                case SocialLoginMessageType.NotFound:
                case SocialLoginMessageType.SwitchMessage:
                    if (requestTypeQuery === 'rental' && signUp && signUp.request) {
                        signUp.request.renterUser.email = res.email!!;
                        setSignUp({...signUp, name: res.name, lastname: res.lastname, authType: authType, email: res.email!!});
                        navigate("/signup/form?role=customer&request=true");
                    } else if(requestTypeQuery === 'sale' && signUp && signUp.saleRequest) {
                        signUp.saleRequest.renterUser.email = res.email!!;
                        setSignUp({...signUp, name: res.name, lastname: res.lastname, authType: authType, email: res.email!!});
                        navigate("/signup/form?role=customer&request=true");    
                    } else {
                        setWarningMessage({message: t('request.create.unknown-error-message'), type: 'error'});
                    }
                    break;
                case SocialLoginMessageType.Blocked:
                    setWarningMessage({message: res.message, type: 'error'});  
                    break;  
                case SocialLoginMessageType.Success:
                    if (signUp) {
                        if (requestTypeQuery === 'sale'  && signUp?.saleRequest) {
                            signUp.saleRequest.renterUser.email = res.email!!;
                            setSignUp({...signUp, name: res.name, lastname: res.lastname, authType: authType, email: res.email!!});
                            navigate("/signup/form?role=customer&request=true");    
                        } else if (requestTypeQuery === 'rental'  && signUp?.request) {
                            signUp.request.renterUser.email = res.email!!;
                            setSignUp({...signUp, name: res.name, lastname: res.lastname, authType: authType, email: res.email!!});
                            navigate("/signup/form?role=customer&request=true");    
                        }
                    }
                    break;
                case SocialLoginMessageType.Otp:
                    if (signUp) {
                        if (requestTypeQuery === 'sale' && signUp.saleRequest) {
                            var response = await doRenterRegisterWithSaleRequest(
                                {...signUp.saleRequest, 
                                    renterUser: 
                                    {...res, ...signUp.saleRequest.renterUser, phone: res.phone?.trim() ?? "" , companyName: "none"}});
                            if (response?.type === SocialLoginMessageType.Success) {
                                setErrorMessage({message: <>{t('common.generic-already-has-account-message-1')}<span style={{fontWeight: 'bold'}}>{t('common.generic-already-has-account-message-2')}</span>{t('common.generic-already-has-account-message-3')}</>, type: 'info'});
                                navigate("/login");
                            }
                            return;
                        }
                        else if (requestTypeQuery === 'rental' && signUp.request) {
                            response = await doRenterRegisterWithRequest(
                                {...signUp.request, 
                                    renterUser: 
                                    {...res, ...signUp.request.renterUser, phone: res.phone?.trim() ?? "" , companyName: "none"}});
                            if (response?.type === SocialLoginMessageType.Success) {
                                setErrorMessage({message: <>{t('common.generic-already-has-account-message-1')}<span style={{fontWeight: 'bold'}}>{t('common.generic-already-has-account-message-2')}</span>{t('common.generic-already-has-account-message-3')}</>, type: 'info'});
                                navigate("/login");
                            }
                            return;
                        }
                    }
                    navigate("/sms-verification?id=" + res?.id + "&op=register&phone=" + res?.message + "&role=customer");
                    break;
                default:
                    setSocialLoginLoading(false);
                    break;
            }
            setSocialLoginLoading(false);
        }
        if (socialLoginCode && signUp && createReqSocialCounterRef.current === 0 && socialLoginType !== null) {
            socialLoginAsync(socialLoginType);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [signUp])

    useEffect(() => {
        const setServiceValues = async () => {
            const result = await doGetMachineSubcategory();
            if (result) setAllMachineCategories(result);
            const cities = await doGetExactCities();
            setAllCities(cities);
        };
        setServiceValues();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        // eslint-disable-next-line
    }, [allMachineCategory]);

    useEffect(() => {
        form.setFieldsValue({ subCategoryOther: undefined });
        setOtherSubCategoryText(undefined);
        // eslint-disable-next-line
    }, [selectedMachineCategory]);

    useEffect(() => {
        additionalValidationNames.current.forEach(x => {
            form.setFieldsValue({ [x]: undefined });
        });
        additionalValidationNames.current = [];
        if (selectedSubcategoryCategory)
            setSaleMachineMustFields(get2SaleMachineMustField({subCategory: {id: selectedSubcategoryCategory?.id, category: selectedMachineCategory}}));
        else setSaleMachineMustFields([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedMachineCategory, selectedSubcategoryCategory]);

    const checkRequestFields = async (values: any): Promise<boolean> => {
        var agreementCheck = await form.validateFields(['agreementVersion', 'kvkkVersion']);

        if (agreementCheck.errorFields) {
            return false;
        }
        if (!selectedSubcategoryCategory && !macid) {
            setErrorMessage({
                message: t("common.generic-required-subcategory-message"),
                type: "error",
            });
            return false;
        }
        if ((await form.validateFields(['city'])).errorFields) {
            setErrorMessage({
                message: "şehir seçiniz",
                type: "error",
            });
            return false;        
        }   
        if (!selectedDistrict) {
            setErrorMessage({
                message: t("common.generic-required-district-message"),
                type: "error",
            });
            return false;
        }
        if (selectedRequestType === 'rental' && (values.requestEndDate === undefined || moment().add(5, "years") < moment(values.requestEndDate).add(1, "day"))) {
            setErrorMessage({
                message: t("request.create.date.validation.end.date"),
                type: "error",
            });
            return false;
        }
        if (selectedRequestType === 'rental' && (values.requestStartDate === undefined || moment().add(1, "year") < moment(values.requestStartDate).add(1, "day"))) {
            setErrorMessage({
                message: t("request.create.date.validation.start.date"),
                type: "error",
            });
            return false;
        }
        return true;
    }

    const getRequestFormValues = async (values: any): Promise<RequestFormCreateWithRegisterModel | undefined> => {
        let formValues: RequestFormCreateWithRegisterModel = values as RequestFormCreateWithRegisterModel;
        const city = allCities?.find((cty) => cty.name === selectedCity?.name);
        const district = city?.districts?.find((dst) => dst.name === selectedDistrict);
        var checkResult = await checkRequestFields(values);
        if (!checkResult) 
            return undefined;  
        formValues.subCategoryId = macid ? machineAllDetail?.subCategoryId ?? 0 : selectedSubcategoryCategory?.id ?? 0;
        formValues.subCategoryOther = otherSubCategoryText;
        formValues.requestDateFrom = moment(values.requestStartDate).toDate() || new Date();
        formValues.requestDateTo = moment(values.requestEndDate).toDate() || new Date();
        formValues.districtId = district?.id ?? 0;
        formValues.withRegister = hasOtp;
        formValues.additionalServices = {
            fuelIncluded: values.fuelIncluded,
            hasOperator: values.hasOperator,
            operatorCostIncluded: values.operatorCostIncluded,
            operatorInsuranceIncluded: values.operatorInsuranceIncluded,
            shipingIncluded: values.shippingIncluded,
        };
        if (macid) {
            formValues.machineId = parseInt(macid);
        }
        
        return formValues;
    }

    const getSaleRequestFormValues = async (values: any): Promise<SaleRequestFormCreateWithRegisterModel | undefined> => {
        let formValues: SaleRequestFormCreateWithRegisterModel = values as SaleRequestFormCreateWithRegisterModel;
        const city = allCities?.find((cty) => cty.name === selectedCity?.name);
        const district = city?.districts?.find((dst) => dst.name === selectedDistrict);
        var checkResult = await checkRequestFields(values);
        if (!checkResult) 
            return undefined;  
        formValues.subCategoryId = macid ? machineAllDetail?.subCategoryId ?? 0 : selectedSubcategoryCategory?.id ?? 0;
        formValues.subCategoryOther = otherSubCategoryText;
        formValues.districtId = district?.id ?? 0;
        formValues.withRegister = hasOtp;
        formValues.description = values.saleDescription;
        formValues.fuelTypeId = values.fuelType;
        return formValues;
    }

    const onFormFinish = async (values: any) => {
        setLoading(true);
        if (selectedRequestType === 'rental') {
            let formValues: RequestFormCreateWithRegisterModel | undefined = await getRequestFormValues(values);
            let registerFormValues = values as RenterCompanyCreateModel;
            if (formValues === undefined)
                return;
            formValues.renterUser = {
                ...registerFormValues,
                agreementVersion:  agreementsVersions.agreementVersion,
                kvkkVersion: agreementsVersions.kvkkVersion,
                ipazVersion: values.ipazVersion ? agreementsVersions.ipazVersion : null,
                sendActivationOTP: hasOtp,
                authType: AuthType.Email,
            };
            registerFormValues.phone= registerFormValues.phone.replace("+90", "");
            registerFormValues.phone = registerFormValues.phone.replace(/\s+/g, "");
            const unmaskedPhoneValue = registerFormValues.phone.replace(/\D/g, ""); 
            registerFormValues.phone = unmaskedPhoneValue;

            setUserPhone(unmaskedPhoneValue);
    
            if (formValues.renterUser) {
                const request = await doRenterRegisterWithRequest(formValues);
                if (hasOtp) {
                    if (request?.type === SocialLoginMessageType.Success) {
                        setErrorMessage({message: <>{t('common.generic-already-has-account-message-1')}<span style={{fontWeight: 'bold'}}>{t('common.generic-already-has-account-message-2')}</span>{t('common.generic-already-has-account-message-3')}</>, type: 'info'});
                        navigate('/login?role=customer');
                    } else if (request?.type === SocialLoginMessageType.NotFound) { // user created during request
                        navigate("/sms-verification?id=" + request.id + "&phone=" + encodeURIComponent(values.phone.replace(/^\+90\s*/, "").replace(/\s+/g, "")) + "&role=customer");
                    }
                } else if (!hasOtp && request) {
                    if (request?.type === SocialLoginMessageType.Success) {
                        setErrorMessage({message: <>{t('common.generic-already-has-account-message-1')}<span style={{fontWeight: 'bold'}}>{t('common.generic-already-has-account-message-2')}</span>{t('common.generic-already-has-account-message-3')}</>, type: 'info'});
                        navigate('/login?role=customer');
                    }
                    setShowRequestSuccessModal(true);
                } else {
                    setErrorMessage({
                        message: t("common.genericError"),
                        type: "error",
                    });
                }
            }
        } else if (selectedRequestType === 'sale') {
            let formValues: SaleRequestFormCreateWithRegisterModel | undefined = await getSaleRequestFormValues(values);
            let registerFormValues = values as RenterCompanyCreateModel;
            if (formValues === undefined)
                return;
            formValues.renterUser = {
                ...registerFormValues,
                agreementVersion:  agreementsVersions.agreementVersion,
                kvkkVersion: agreementsVersions.kvkkVersion,
                ipazVersion: values.ipazVersion ? agreementsVersions.ipazVersion : null,
                sendActivationOTP: hasOtp,
                authType: AuthType.Email,
            };
            registerFormValues.phone= registerFormValues.phone.replace("+90", "");
            registerFormValues.phone = registerFormValues.phone.replace(/\s+/g, "");
            const unmaskedPhoneValue = registerFormValues.phone.replace(/\D/g, ""); 
            registerFormValues.phone = unmaskedPhoneValue;
            setUserPhone(unmaskedPhoneValue);
            if (formValues.renterUser) {
                const request = await doRenterRegisterWithSaleRequest(formValues);
                if (hasOtp) {
                    if (request?.type === SocialLoginMessageType.Success) {
                        setErrorMessage({message: <>{t('common.generic-already-has-account-message-1')}<span style={{fontWeight: 'bold'}}>{t('common.generic-already-has-account-message-2')}</span>{t('common.generic-already-has-account-message-3')}</>, type: 'info'});
                        navigate('/login');
                    } else if (request?.type === SocialLoginMessageType.NotFound) { // user created during request
                        navigate("/sms-verification?id=" + request.id + "&phone=" + encodeURIComponent(values.phone.replace(/^\+90\s*/, "").replace(/\s+/g, "")) + "&role=customer");
                    }
                } else if (!hasOtp && request) {
                    if (request?.type === SocialLoginMessageType.Success) {
                        setErrorMessage({message: <>{t('common.generic-already-has-account-message-1')}<span style={{fontWeight: 'bold'}}>{t('common.generic-already-has-account-message-2')}</span>{t('common.generic-already-has-account-message-3')}</>, type: 'info'});
                        navigate('/login');
                    }
                    setShowRequestSuccessModal(true);
                } else {
                    setErrorMessage({
                        message: t("common.genericError"),
                        type: "error",
                    });
                }
            }
        }
        setLoading(false)
    };
    const createCountChanged = (num: number) => {
        setCreateCount(num);
    };

    const detailTextAreaChanged = (val: ChangeEvent<HTMLTextAreaElement>) => {
        setDetailText(val.currentTarget.value);
    };

    const onCitySelected = async (selectedCityName: string) => {
        //if (myRef.current) myRef.current.scrollIntoView({behavior: 'smooth'})
        setDistrict(undefined);
        form.resetFields(['district']);
        const currentCity = allCities?.find((city) => city.name === selectedCityName);
        setCity(currentCity);
        if (currentCity && currentCity.districts) setDistrictsDropdown(currentCity.districts);
        else setDistrictsDropdown(undefined);
    };

    const disabledStartDate = (current: any) => {
        // Can not select days before today and today
        return (current && current <= moment().endOf("day")) || (selectedDates?.[1] && selectedDates?.[1]?.endOf("day") && current > selectedDates?.[1]?.endOf("day"));
    }
    const disabledEndDate = (current: any) => {
        // Can not select days before today and today
        return current && ((current <= moment().endOf("day")) || (selectedDates?.[0]?.endOf("day") && current < selectedDates?.[0]?.endOf("day")));
    }

    const handleSelectedAgreement = (showModal: boolean, linkSrc: string, title: string) => {
        setShowReadAgreementModal(showModal);
        setSelectedAgreementSrc(linkSrc);
        setSelectedAgreementTitle(title);
    };

    const checkAdditionalMustFieldsError = (e: ValidateErrorEntity<any>) => {
        const errAdditional = e.errorFields.find(d => {return additionalValidationNames.current.includes(d.name.toString())});
        if (errAdditional) {
            form.scrollToField(errAdditional.name, {behavior: 'smooth', block: 'center',inline: 'center'});
            return true;
        }
        return false;
    }

    return (
        <OnboardLayout hideBackgroundImg hideLogo smallLogo={true}>
            {socialLoginLoading ? (
                <Spin size="large" tip="Lütfen Bekleyiniz" className={styles.spinMobile} /> )
            : (
            <>
            <div className={styles.container}>
                <div className={styles.segmentContainer}>
                    <div className={styles.segmentInnerContainer}>
                        <Segmented 
                            defaultOption={selectedRequestType === 'rental' ? 0 : 1}
                            disabled={query.get("macid") !== null ? [1] : []}
                            values={[t('request-type-rental'), t('request-type-sale')]} 
                            onChange={(e) => {
                                form.resetFields();
                                setSelectedSubcategoryCategory(undefined);
                                setSelectedMachineCategory(undefined);
                                setSignUp(null);
                                setSaleMachineMustFields([]);
                                additionalValidationNames.current = [];
                                setselectedRequestType(e === t('request-type-rental') ? 'rental' : 'sale');
                            }}/>
                    </div>
                    <div className={styles.segmentInfoContainer}>
                        <InfoIcon className={styles.icon}/>
                        <p>{t('request-type-info-1') + ' '}{selectedRequestType === 'rental' ? <span>{t('request-type-info-2')}</span> : <span>{t('request-type-info-3')}</span>} {' ' + t('request-type-info-4')}</p>
                    </div>
                </div>
            <Form key={1} onFinishFailed={(e) => {
                const errAdditional = checkAdditionalMustFieldsError(e);
                if (errAdditional)
                    return;
                for (let err of e.errorFields) {
                    form.scrollToField(err.name, {behavior: 'smooth', block: 'center',inline: 'center'});
                    break;
                }
            }} name="add-request" layout="vertical" form={form} onFinish={onFormFinish}>
                <Row gutter={[16, 16]} align="middle" justify="center">
                    <Col xs={24} md={24} className={styles.machineInfoTitle}>
                        <div>
                            <h3>{t("machines.add-info.cardTitle")}</h3>
                        </div>
                    </Col>
                    <Col xs={24}>
                        {macid && selectedRequestType === 'rental' && machineAllDetail ? (
                            <MachineCard onFooterButtonClicked={() => {
                                setShowRedirectModal(true);
                            }} cardType="rental" machine={machineAllDetail} />
                        ) : (
                            <>
                            <MachineCategorySelect 
                                form={form}
                                height="90px"
                                label={t('request.machineCategory')}
                                required={macid === null}
                                errorMessage={t('request.validationGeneralError')}
                                placeholder={t('common.select')}
                                categories={allMachineCategory ?? []}
                                onChange={(category) => {
                                    form.setFieldsValue(
                                        {
                                            subcategory: undefined,
                                        }
                                    );
                                    setSelectedSubcategoryCategory(undefined);
                                    setSelectedMachineCategory(category);
                                }}
                            />
                            <div className={styles.section}>
                                <MachineSubcategorySelect 
                                    key={'subcategory-select'}
                                    form={form}
                                    height="90px"
                                    label={t('request.select-machineSubcategory')}
                                    required={macid === null}
                                    errorMessage={t('request.validationGeneralError')}
                                    placeholder={t('common.select')}
                                    subcategories={selectedMachineCategory?.subCategories ?? []}
                                    disabled={selectedMachineCategory === undefined}
                                    onChange={(subcategory) => {
                                        if (subcategory?.id !== 79) {
                                            setOtherSubCategoryText(undefined);
                                            form.setFieldsValue({
                                                otherSubcategory: undefined
                                            });
                                        } else {
                                            setOtherSubCategoryText(subcategory.name?.trim() ?? "");   
                                        }
                                        setSelectedSubcategoryCategory(subcategory);
                                    }}
                                />
                            </div>
                            {
                                selectedRequestType === 'sale' ? (                            
                                <div className={styles.section}>
                                    <Form.Item
                                        style={{marginBottom: 0}}
                                        label={t('request.select-brand')}
                                        name={'brand'}
                                    >
                                        <Input placeholder={t('request.brand-placeholder')}></Input>
                                    </Form.Item>
                                </div>) 
                                : 
                                (<></>)
                            }
                            </>
                        )}
                    </Col>
                    {
                        selectedRequestType === 'rental' ? ( 
                            <Col className={styles.machineCountContainer} style={{marginTop: '12px'}} xs={24}>
                                <Form.Item label={t("request.machineCountLabel")} name="machineCount">
                                    <IncrementsInputVersion2 min={1} max={50} value={createCount} onChange={createCountChanged} />
                                </Form.Item>
                            </Col>
                         ) : (<></>)
                    }
                    {
                        selectedRequestType === 'sale' ? (
                            <>
                                {
                                    saleMachineMustFields ? 
                                    (
                                        saleMachineMustFields.map((field, i) => {
                                            if (field.mustFieldName === 'operatingWeight') {
                                                if (!additionalValidationNames.current.includes('minOperatingWeight'))
                                                    additionalValidationNames.current = [...additionalValidationNames.current, 'minOperatingWeight', 'maxOperatingWeight'];
                                                return (
                                                    <Col xs={24} className={i !== 0 ? styles.section : ''}>
                                                        <MinMaxInput required minPlaceholder={t("common.lowest")} maxPlaceholder={t("common.highest")} label={t('common.operatingWeight')} min={0} form={form} minFieldName={"minOperatingWeight"} maxFieldName="maxOperatingWeight" />
                                                    </Col>
                                                );
                                            } else if (field.mustFieldName === 'operationHour'){
                                                if (!additionalValidationNames.current.includes('minOperationHour'))
                                                    additionalValidationNames.current = [...additionalValidationNames.current, 'minOperationHour', 'maxOperationHour'];
                                                return (
                                                    <Col xs={24} className={i !== 0 ? styles.section : ''}>
                                                        <MinMaxInput required minPlaceholder={t("common.lowest")} maxPlaceholder={t("common.highest")} label={t('common.operationHour')} min={0} form={form} minFieldName={"minOperationHour"} maxFieldName="maxOperationHour" />
                                                    </Col>
                                                );
                                            } else if (field.mustFieldName === 'carryingCapacity'){
                                                if (!additionalValidationNames.current.includes('minCarryingCapacity'))
                                                    additionalValidationNames.current = [...additionalValidationNames.current, 'minCarryingCapacity', 'maxCarryingCapacity'];
                                                return (
                                                    <Col xs={24} className={i !== 0 ? styles.section : ''}>
                                                        <MinMaxInput required minPlaceholder={t("common.lowest")} maxPlaceholder={t("common.highest")} label={t('common.carryingCapacity')} min={0} form={form} minFieldName={"minCarryingCapacity"} maxFieldName="maxCarryingCapacity" />
                                                    </Col>
                                                );
                                            } else if (field.mustFieldName === 'operatingHeight'){
                                                if (!additionalValidationNames.current.includes('minOperatingHeight'))
                                                    additionalValidationNames.current = [...additionalValidationNames.current, 'minOperatingHeight', 'maxOperatingHeight'];
                                                return (
                                                    <Col xs={24} className={i !== 0 ? styles.section : ''}>
                                                        <MinMaxInput required minPlaceholder={t("common.lowest")} maxPlaceholder={t("common.highest")} label={t('common.operatingHeight')} min={0} form={form} minFieldName={"minOperatingHeight"} maxFieldName="maxOperatingHeight" />
                                                    </Col>
                                                );
                                            } else if (field.mustFieldName === 'operationMileage'){
                                                if (!additionalValidationNames.current.includes('minOperationMileage'))
                                                    additionalValidationNames.current = [...additionalValidationNames.current, 'minOperationMileage', 'maxOperationMileage'];
                                                return (
                                                    <Col xs={24} className={i !== 0 ? styles.section : ''}>
                                                        <MinMaxInput required minPlaceholder={t("common.lowest")} maxPlaceholder={t("common.highest")} label={t('common.operationMileage')} min={0} form={form} minFieldName={"minOperationMileage"} maxFieldName="maxOperationMileage" />
                                                    </Col>
                                                );
                                            } else if (field.mustFieldName === 'spraySpeed'){
                                                if (!additionalValidationNames.current.includes('minSpraySpeed'))
                                                    additionalValidationNames.current = [...additionalValidationNames.current, 'minSpraySpeed', 'maxSpraySpeed'];
                                                return (
                                                    <Col xs={24} className={i !== 0 ? styles.section : ''}>
                                                        <MinMaxInput required minPlaceholder={t("common.lowest")} maxPlaceholder={t("common.highest")} label={t('common.spraySpeed')} min={0} form={form} minFieldName={"minSpraySpeed"} maxFieldName="maxSpraySpeed" />
                                                    </Col>
                                                );
                                            } else if (field.mustFieldName === 'tankVolume'){
                                                if (!additionalValidationNames.current.includes('minTankVolume'))
                                                    additionalValidationNames.current = [...additionalValidationNames.current, 'minTankVolume', 'maxTankVolume'];
                                                return (
                                                    <Col xs={24} className={i !== 0 ? styles.section : ''}>
                                                        <MinMaxInput required minPlaceholder={t("common.lowest")} maxPlaceholder={t("common.highest")} label={t('common.tankVolume')} min={0} form={form} minFieldName={"minTankVolume"} maxFieldName="maxTankVolume" />
                                                    </Col>
                                                );
                                            } else if (field.mustFieldName === 'liftingHeight'){
                                                if (!additionalValidationNames.current.includes('minLiftingHeight'))
                                                    additionalValidationNames.current = [...additionalValidationNames.current, 'minLiftingHeight', 'maxLiftingHeight'];
                                                return (
                                                    <Col xs={24} className={i !== 0 ? styles.section : ''}>
                                                        <MinMaxInput required minPlaceholder={t("common.lowest")} maxPlaceholder={t("common.highest")} label={t('common.liftingHeight')} min={0} form={form} minFieldName={"minLiftingHeight"} maxFieldName="maxLiftingHeight" />
                                                    </Col>
                                                );
                                            } else if (field.mustFieldName === 'nominalCapacity'){
                                                if (!additionalValidationNames.current.includes('minNominalCapacity'))
                                                    additionalValidationNames.current = [...additionalValidationNames.current, 'minNominalCapacity', 'maxNominalCapacity'];
                                                return (
                                                    <Col xs={24} className={i !== 0 ? styles.section : ''}>
                                                        <MinMaxInput required minPlaceholder={t("common.lowest")} maxPlaceholder={t("common.highest")} label={t('common.nominalCapacity')} min={0} form={form} minFieldName={"minNominalCapacity"} maxFieldName="maxNominalCapacity" />
                                                    </Col>
                                                );
                                            } else if (field.mustFieldName === 'maxHeight'){
                                                if (!additionalValidationNames.current.includes('minHeight'))
                                                    additionalValidationNames.current = [...additionalValidationNames.current, 'minHeight', 'maxHeight'];
                                                return (
                                                    <Col xs={24} className={i !== 0 ? styles.section : ''}>
                                                        <MinMaxInput required minPlaceholder={t("common.lowest")} maxPlaceholder={t("common.highest")} label={t('common.maxHeight')} min={0} form={form} minFieldName={"minHeight"} maxFieldName={"maxHeight"} />
                                                    </Col>
                                                );
                                            } else if (field.mustFieldName === 'enginePower'){
                                                if (!additionalValidationNames.current.includes('minEnginePower'))
                                                    additionalValidationNames.current = [...additionalValidationNames.current, 'minEnginePower', 'maxEnginePower'];
                                                return (
                                                    <Col xs={24} className={i !== 0 ? styles.section : ''}>
                                                        <MinMaxInput required minPlaceholder={t("common.lowest")} maxPlaceholder={t("common.highest")} label={t('common.enginePower')} min={0} form={form} minFieldName={"minEnginePower"} maxFieldName="maxEnginePower" />
                                                    </Col>
                                                );
                                            } else if (field.mustFieldName === 'maxPower'){
                                                if (!additionalValidationNames.current.includes('minMaxPower'))
                                                    additionalValidationNames.current = [...additionalValidationNames.current, 'minMaxPower', 'maxMaxPower'];
                                                return (
                                                    <Col xs={24} className={i !== 0 ? styles.section : ''}>
                                                        <MinMaxInput required minPlaceholder={t("common.lowest")} maxPlaceholder={t("common.highest")} label={t('common.maxPower')} min={0} form={form} minFieldName={"minMaxPower"} maxFieldName="maxMaxPower" />
                                                    </Col>
                                                );
                                            } else if (field.mustFieldName === 'volume'){
                                                if (!additionalValidationNames.current.includes('minVolume'))
                                                    additionalValidationNames.current = [...additionalValidationNames.current, 'minVolume', 'maxVolume'];
                                                return (
                                                    <Col xs={24} className={i !== 0 ? styles.section : ''}>
                                                        <MinMaxInput required minPlaceholder={t("common.lowest")} maxPlaceholder={t("common.highest")} label={t('common.volume')} min={0} form={form} minFieldName={"minVolume"} maxFieldName="maxVolume" />
                                                    </Col>
                                                );
                                            } else if (field.mustFieldName === 'tankCapacity'){
                                                if (!additionalValidationNames.current.includes('minTankCapacity'))
                                                    additionalValidationNames.current = [...additionalValidationNames.current, 'minTankCapacity', 'maxTankCapacity'];
                                                return (
                                                    <Col xs={24} className={i !== 0 ? styles.section : ''}>
                                                        <MinMaxInput required minPlaceholder={t("common.lowest")} maxPlaceholder={t("common.highest")} label={t('common.tankCapacity')} min={0} form={form} minFieldName={"minTankCapacity"} maxFieldName="maxTankCapacity" />
                                                    </Col>
                                                );
                                            } else if (field.mustFieldName === 'loadCapacity'){
                                                if (!additionalValidationNames.current.includes('minLoadCapacity'))
                                                    additionalValidationNames.current = [...additionalValidationNames.current, 'minLoadCapacity', 'maxLoadCapacity'];
                                                return (
                                                    <Col xs={24} className={i !== 0 ? styles.section : ''}>
                                                        <MinMaxInput required minPlaceholder={t("common.lowest")} maxPlaceholder={t("common.highest")} label={t('common.loadCapacity')} min={0} form={form} minFieldName={"minLoadCapacity"} maxFieldName="maxLoadCapacity" />
                                                    </Col>
                                                );
                                            } else if (field.mustFieldName === 'maxLoadCapacity'){
                                                if (!additionalValidationNames.current.includes('minMaxLoadCapacity'))
                                                    additionalValidationNames.current = [...additionalValidationNames.current, 'minMaxLoadCapacity', 'maxMaxLoadCapacity'];
                                                return (
                                                    <Col xs={24} className={i !== 0 ? styles.section : ''}>
                                                        <MinMaxInput required minPlaceholder={t("common.lowest")} maxPlaceholder={t("common.highest")} label={t('common.maxLoadCapacity')} min={0} form={form} minFieldName={"minMaxLoadCapacity"} maxFieldName="maxMaxLoadCapacity" />
                                                    </Col>
                                                );
                                            } else if (field.mustFieldName === 'flightTime'){
                                                if (!additionalValidationNames.current.includes('minFlightTime'))
                                                    additionalValidationNames.current = [...additionalValidationNames.current, 'minFlightTime', 'maxFlightTime'];
                                                return (
                                                    <Col xs={24} className={i !== 0 ? styles.section : ''}>
                                                        <MinMaxInput required minPlaceholder={t("common.lowest")} maxPlaceholder={t("common.highest")} label={t('common.flightTime')} min={0} form={form} minFieldName={"minFlightTime"} maxFieldName="maxFlightTime" />
                                                    </Col>
                                                );
                                            } else if (field.mustFieldName === 'drivingEquipment'){
                                                if (!additionalValidationNames.current.includes('drivingEquipment'))
                                                    additionalValidationNames.current = [...additionalValidationNames.current, 'drivingEquipment'];
                                                return (
                                                    <Col xs={24} className={i !== 0 ? styles.section : ''}>
                                                        <Form.Item
                                                            style={{marginBottom: '0'}}
                                                            name="drivingEquipment"
                                                            label={<span style={{fontSize: '16px', fontWeight: '800', color: '#282828'}}>{t("machines.additional-fields.drivingEquipment")}</span>}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: "",
                                                                },
                                                            ]}
                                                        >
                                                            <Select getPopupContainer={(trigger: any) => trigger.parentElement}>
                                                                <Option value="Paletli">Paletli</Option>
                                                                <Option value="Lastikli">Lastikli</Option>
                                                                <Option value="Dört eşit tekerlek">Dört eşit tekerlek</Option>
                                                                <Option value="Ön tekerler küçük">Ön tekerler küçük</Option>
                                                                <Option value="Diger">Diger</Option>
                                                            </Select>
                                                        </Form.Item>
                                                    </Col>
                                                );
                                            } else if (field.mustFieldName === 'fuelType') {
                                                if (!additionalValidationNames.current.includes('fuelType'))
                                                    additionalValidationNames.current = [...additionalValidationNames.current, 'fuelType'];
                                                return (
                                                <Col xs={24}>
                                                    <Form.Item
                                                        style={{marginBottom: '0'}}
                                                        name="fuelType"
                                                        label={<span style={{fontSize: '16px', fontWeight: '800', color: '#282828'}}>{t("machines.additional-fields.fuelTypeId")}</span>}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: "",
                                                            },
                                                        ]}
                                                    >
                                                        <Select getPopupContainer={(trigger: any) => trigger.parentElement} loading={loading}>
                                                            {fuelTypes?.map((msc) => (
                                                                <Option key={msc.name} value={msc.id}>
                                                                    {msc.name}
                                                                </Option>
                                                            ))}
                                                        </Select>
                                                    </Form.Item>
                                                </Col>);
                                            }
                                            return (<></>);
                                        })
                                    ) 
                                    : (<></>)
                                }
                                <Col xs={24} className={styles.section} >
                                    <MinMaxCollapseInput minPlaceholder={t("common.lowest")} maxPlaceholder={t("common.highest")} label={t('common.productionYear-2')} min={1970} max={new Date().getFullYear()} form={form} minFieldName={"minProductionYear"} maxFieldName="maxProductionYear" />
                                </Col>
                            </> 

                        ) : (<></>)
                    }
                    <Col className={styles.locationContainer + ' ' + styles.section} xs={24}>
                        <h3>{t("request.locationLabel")}</h3>
                        <Row style={{ marginBottom: "1.5rem" }} gutter={[4,0]} justify="end" wrap={false}>
                            <Col xs={12} md={12}>
                                <Form.Item
                                    name={"city"}
                                    rules={[
                                        {
                                            required: true,
                                            message: t("request.location-city-error"),
                                        }
                                    ]}
                                >
                                    <Select
                                        placeholder={t("request.location.city.placeholder")}
                                        loading={loading}
                                        onSelect={onCitySelected}
                                        style={{ width: "100%" }}
                                        value={selectedCity?.name}
                                        getPopupContainer={(trigger: any) => trigger.parentElement}
                                    >
                                        {allCities?.map((mc, i) => (
                                            <Option key={'city-select-' + i} value={mc.name}>{mc.name}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={12} md={12}>
                                <Form.Item
                                    name={"district"}
                                    rules={[
                                        {
                                            required: true,
                                            message: t("request.location-district-error"),
                                        }
                                    ]}
                                >
                                    <Select
                                        placeholder={t("request.location.district.placeholder")}
                                        loading={loading}
                                        style={{ width: "100%" }}
                                        value={selectedDistrict}
                                        onSelect={(e: string) => setDistrict(e)}
                                        getPopupContainer={(trigger: any) => trigger.parentElement}
                                    >
                                        {districts?.map((msc) => (
                                            <Option value={msc.name}>{msc.name}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                    {
                        selectedRequestType === 'sale' ? ( 
                            <>
                            <Col className={styles.section} xs={24}>
                                <MinMaxCollapseInput minPlaceholder={t("common.maxPrice")} maxPlaceholder={t("common.minPrice")} label={t('common.priceRange') + ' (₺)'} min={0} form={form} minFieldName={"minPrice"} maxFieldName="maxPrice" />
                            </Col>
                            <Col xs={24} className={styles.section}>
                                <Collapse expandIconPosition="right" defaultActiveKey={0} accordion className={styles.saleDescriptionCollapse}>
                                    <CollapsePanel className={styles.panel} key={1} header={t("common.detail")}>
                                        <Col xs={24} className={styles.saleDescriptionContainer} style={{marginTop: '24px'}}>
                                            <div className={styles.saleTooltip}>
                                                <span className={styles.tooltiptext}>{t("request.description-tooltip")}</span>
                                                <TransparentInfoIcon className={styles.infoIcon}/>
                                            </div>
                                            <Form.Item name={"saleDescription"} noStyle>
                                                <TextArea
                                                    placeholder={t("request.machine-detail.writeDetailsPlaceholder")}
                                                    onChange={detailTextAreaChanged}
                                                    value={detailText}
                                                    maxLength={500}
                                                    showCount
                                                ></TextArea>
                                            </Form.Item>
                                        </Col>
                                    </CollapsePanel>
                                </Collapse>
                            </Col>
                            </>
                        ) : (<></>)
                    }
                    {
                        selectedRequestType === 'rental' ? (
                        <>
                        <Col className={styles.dateContainer + ' ' + styles.section} md={24} xs={24}>
                            <Row>
                                <Col xs={24}>
                                    <h3>{t("request.dateLabel")}</h3>
                                </Col>
                            </Row>
                            <Row gutter={[4, 0]}>
                                <Col xs={12}>
                                <Form.Item
                                    name={"requestStartDate"}
                                    rules={[
                                        {
                                            required: selectedRequestType === 'rental',
                                            validator: (_, value) =>
                                                value
                                                    ? Promise.resolve()
                                                    : Promise.reject(new Error(t("common.generic-required-message"))),
                                        },
                                    ]}
                                >
                                    <DatePicker 
                                        value={selectedDates?.[0]}
                                        disabledDate={disabledStartDate}
                                        locale={trLocale}
                                        format="DD-MM-YYYY"
                                        inputReadOnly={mediaQueryList.matches}
                                        onChange={(val) => {
                                            setSelectedDates((old) => {
                                                const range: RangeValue<moment.Moment> = [ val, old?.[1] ? moment(old?.[1]) : val]
                                                return (range);
                                            });
                                        }}
                                        placeholder={t("request.date-start-label")}
                                        className={styles.datePicker}
                                    />
                                </Form.Item>
                                </Col>
                                <Col xs={12}>
                                <Form.Item
                                    name={"requestEndDate"}
                                    rules={[
                                        {
                                            required: selectedRequestType === 'rental',
                                            validator: (_, value) =>
                                                value
                                                    ? Promise.resolve()
                                                    : Promise.reject(new Error(t("common.generic-required-message"))),
                                        },
                                    ]}
                                >
                                    <DatePicker 
                                        value={selectedDates?.[1]}
                                        disabledDate={disabledEndDate}
                                        locale={trLocale}
                                        format="DD-MM-YYYY"
                                        inputReadOnly={mediaQueryList.matches}
                                        onChange={(val) => {
                                            setSelectedDates((old) => {
                                                const range: RangeValue<moment.Moment> = [ moment(old?.[0]) , val ]
                                                return (range);
                                            });
                                        }}
                                        placeholder={t("request.date-end-label")}
                                        className={styles.datePicker}
                                    />
                                </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                    <Col xs={24} className={styles.section}>
                        <Collapse expandIconPosition="right" defaultActiveKey={0} accordion className={styles.additionalServicesCollapse}>
                            <CollapsePanel className={styles.panel} key={1} header={t("machines.add-info.additionalServices")}>
                                <Row gutter={[24, 24]}>
                                    <Col xs={24} className={styles.additionalServiceCheckboxContainer}>
                                        <Form.Item
                                            name={"hasOperator"}
                                            valuePropName="checked"
                                            style={{marginBottom: 0}}
                                        >
                                            <Checkbox>{t("request.hasOperator")}</Checkbox>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} className={styles.additionalServiceCheckboxContainer}>
                                    <Form.Item
                                        style={{marginBottom: 0}}
                                        name={"fuelIncluded"}
                                        valuePropName="checked"
                                    >
                                            <Checkbox>{t("request.fuelIncluded")}</Checkbox>
                                    </Form.Item>
                                    </Col>
                                    <Col xs={24} className={styles.additionalServiceCheckboxContainer}>
                                        <Form.Item
                                            style={{marginBottom: 0}}
                                            name={"shippingIncluded"}
                                            valuePropName="checked"
                                        >
                                            <Checkbox>{t("request.shippingIncluded")}</Checkbox>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Col xs={24} className={styles.descriptionContainer}>
                                    <h3>{t("common.detail")}</h3>
                                    <div className={styles.tooltip}>
                                        <span className={styles.tooltiptext}>{t("request.description-tooltip")}</span>
                                        <TransparentInfoIcon className={styles.infoIcon}/>
                                    </div>
                                    <Form.Item name={"description"} noStyle>
                                        <TextArea
                                            style={{ marginBottom: "2rem" }}
                                            placeholder={t("request.machine-detail.writeDetailsPlaceholder")}
                                            onChange={detailTextAreaChanged}
                                            value={detailText}
                                            maxLength={500}
                                            showCount
                                        ></TextArea>
                                    </Form.Item>
                                </Col>
                            </CollapsePanel>
                        </Collapse>
                    </Col>
                    </>
                        ) : (<></>)
                    }
                    <Col xs={24} className={styles.contactTitle + ' ' + styles.section}>
                        <h3>{t("request.usert-contact-title")}</h3>
                    </Col>
                    <Col xs={24}>
                        <Form.Item
                            label={t("signup.form.nameLabel")}
                            name="name"
                            rules={[
                                {pattern: /^[a-zA-ZğüşıöçĞÜŞİÖÇ\s]*$/,message: t('anonym-create-request.name-field')},
                                {
                                    required: true,
                                    type: "string",
                                    message: t("signup.form.nameValidation"),
                                },
                            ]}
                        >
                            <Input className={styles.contactInput} placeholder={t("request.name-input-placeholder")} />
                        </Form.Item>
                    </Col>
                    <Col xs={24}>
                        <Form.Item
                            label={t("signup.form.surnameLabel")}
                            name="lastName"
                            rules={[
                                {pattern: /^[a-zA-ZğüşıöçĞÜŞİÖÇ\s]*$/,message: t('anonym-create-request.surname-field')},
                                {
                                    required: true,
                                    type: "string",
                                    message: t("signup.form.surnameValidation"),
                                },
                            ]}
                        >
                            <Input className={styles.contactInput} placeholder={t("request.lastname-input-placeholder")} />
                        </Form.Item>
                    </Col>
                    <Col xs={24}>
                        <Form.Item
                            label={t("signup.form.emailLabel")}
                            name="email"
                            rules={[
                                {
                                    required: true,
                                    validator: (_, value) => {
                                        if (value === undefined || value === '') {
                                            return Promise.reject(t("signup.form.emailValidation"));
                                        } else if (!(/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value))) {
                                            return Promise.reject(t("anonym-create-request.email-validation-format-message"));
                                        } else {
                                            return Promise.resolve();
                                        }
                                    }
                                }
                            ]}
                        >
                            <Input onKeyDownCapture={(key) => {
                                if (!/[a-zA-Z0-9.@]/.test(key.key)) {
                                    key.preventDefault();
                                }
                            }} onChange={(e) => {
                                if (e.currentTarget.value.includes('^')) {
                                    form.setFieldsValue({ email: form.getFieldValue('email').replaceAll('^', '') });
                                }
                            }} className={styles.contactInput} placeholder={t("request.email-input-placeholder")} />
                        </Form.Item>
                    </Col>
                    <Col xs={24}>
                        <UsernameInput key={'phone-input-' + selectedRequestType} placeholder={t("request.phone-input-placeholder")} prefix={false} />
                    </Col>
                    <Col xs={24}>
                        <Form.Item
                            label={t("signup.form.corpLabel")}
                            name="companyName"
                            rules={[
                                {
                                    required: true,
                                    type: "string",
                                    message: t("signup.form.corpValidation"),
                                },
                            ]}
                        >
                            <Input className={styles.contactInput} placeholder={t("request.companyname-input-placeholder")} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} className={styles.agreementCheckboxContainer + ' ' + styles.noMargin}>
                        <Form.Item
                            name="agreementVersion"
                            valuePropName="checked"
                            rules={[
                                {
                                    required: true,
                                    type: "boolean",
                                    message: t("signup.form.bhsValidation"),
                                    validator: (_, value) =>
                                        value ? Promise.resolve() : Promise.reject(new Error(t("common.generic-required-message"))),
                                },
                            ]}
                        >
                            <Checkbox>
                                <LinkButton
                                    onClick={() =>
                                        handleSelectedAgreement(true, activeRootSiteUrl + "/kullanici-sozlesmesi/", "agreementVersion")
                                    }
                                    target="_blank"
                                >
                                    {t("signup.form.bhsName")}
                                </LinkButton>
                                {t("signup.form.bhsLabel")}
                            </Checkbox>
                        </Form.Item>
                    </Col>
                    <Col xs={24} className={styles.agreementCheckboxContainer}>
                        <Form.Item
                            name="kvkkVersion"
                            valuePropName="checked"
                            rules={[
                                {
                                    type: "boolean",
                                    message: t("signup.form.kvkkValidation"),
                                    required: true,
                                    validator: (_, value) =>
                                        value ? Promise.resolve() : Promise.reject(new Error(t("common.generic-required-message"))),
                                },
                            ]}
                        >
                            <Checkbox>
                                <LinkButton
                                    onClick={() => handleSelectedAgreement(true, activeRootSiteUrl + "/aydinlatma-metni/", "kvkkVersion")}
                                    target="_blank"
                                >
                                    {t("signup.form.kvkkName")}
                                </LinkButton>
                                <span> {t("signup.form.kvkkLabel")}</span>
                            </Checkbox>
                        </Form.Item>
                    </Col>
                    <Col xs={24} className={styles.agreementCheckboxContainer}>
                        <Form.Item
                            name="ipazVersion"
                            valuePropName="checked"
                            rules={[
                                {
                                    type: "boolean",
                                    message: t("signup.form.ipazValidation"),
                                },
                            ]}
                        >
                            <Checkbox>
                                <LinkButton
                                    onClick={() =>
                                        handleSelectedAgreement(true, activeRootSiteUrl + "/izinli-pazarlama-onay-metni/", "ipazVersion")
                                    }
                                    target="_blank"
                                >
                                    {t("signup.form.ipazName")}
                                </LinkButton>
                                <span> {t("signup.form.ipazLabel")}</span>
                            </Checkbox>
                        </Form.Item>
                    </Col>
                    <Row justify="center" style={{width: '100%'}} gutter={[12,12]}>
                        <Col hidden={!googleLoginActive} xs={24} md={12}>
                            <SocialAuthButton style={{border: '1px solid #94939433'}} type="google" onClick={async () => {
                                try {
                                    await form.validateFields(["category", "subcategory", "city", "district", "requestStartDate", "requestEndDate", "agreementVersion", "kvkkVersion"].concat(additionalValidationNames.current));
                                } catch (e: any) {
                                    const errAdditional = checkAdditionalMustFieldsError(e);
                                    if (errAdditional)
                                        return;
                                    for (let err of e.errorFields) {
                                        form.scrollToField(err.name, {behavior: 'smooth', block: 'center',inline: 'center'});
                                        return;
                                    }
                                }
                                await setSocialRequestAtomWithoutEmail(AuthType.Google);
                                googleLogin(redirectRegisterAnonymPageUri, "loginType:" + AuthType.Google + "&reqType:" + selectedRequestType);
                            }} text={t('signup.google.button-text')}/>       
                        </Col>
                        <Col hidden={!facebookLoginActive} xs={24} md={12}>
                            <SocialAuthButton style={{border: '1px solid #94939433'}} type="facebook" onClick={async () => {
                                try {
                                    await form.validateFields(["category", "subcategory", "city", "district", "requestStartDate", "requestEndDate", "agreementVersion", "kvkkVersion"].concat(additionalValidationNames.current));
                                } catch (e: any) {
                                    const errAdditional = checkAdditionalMustFieldsError(e);
                                    if (errAdditional)
                                        return;
                                    for (let err of e.errorFields) {
                                        form.scrollToField(err.name, {behavior: 'smooth', block: 'center',inline: 'center'});
                                        return;
                                    }
                                }
                                await setSocialRequestAtomWithoutEmail(AuthType.Facebook);
                                facebookLogin(redirectRegisterAnonymPageUri, "loginType:" + AuthType.Facebook + "&reqType:" + selectedRequestType);
                            }} text={t('signup.facebook.button-text')}/>                  
                        </Col>
                            <Col
                                md={12}
                                xs={24}
                                style={{height: '48px'}}
                                onClick={() => {
                                    setHasOtp(false);
                                }}
                            >
                                <Form.Item>
                                    <PrimaryButton
                                        loading={loading}
                                        htmlType="submit"
                                        style={{backgroundColor: "#FFFFFF", border: "none", color: "#282828", borderRadius: '12px', fontSize: '14px', fontWeight: '800'}}
                                    >
                                        {t("request.send-without-register")}
                                    </PrimaryButton>
                                </Form.Item>
                            </Col>
                            <Col
                                md={12}
                                xs={24}
                                style={{height: '48px'}}
                                onClick={() => {
                                    setHasOtp(true);
                                }}
                            >
                                <Form.Item>
                                    <PrimaryButton style={{color: "#282828", fontSize: '14px', borderRadius: '12px',  fontWeight: '800'}} loading={loading} htmlType="submit">
                                        {t("request.send-with-register")}
                                    </PrimaryButton>
                                </Form.Item>
                            </Col>
                        </Row>
                </Row>
            </Form>
            <Col xs={24} className={styles.section}>
                <ContactMachinego type="tel"/>
            </Col>
            <ModalLayout
                show={showAlreadyRegisteredModal}
                onCloseClick={() => {
                    setShowAlreadyRegisteredModal(false);
                }}
            >
                <Row wrap={true} justify="center" align="middle">
                    <Col md={16} xs={16}>
                        <h2 style={{ textAlign: "center" }}>{t("request.create.already.registered.modal.title")}</h2>
                    </Col>
                    <Col md={12} xs={16}>
                        <p style={{ textAlign: "center" }}>{t("request.create.already.registered.modal.text")}</p>
                    </Col>
                    <Col md={24} xs={24}>
                        <PrimaryButton
                            onClick={() => {
                                navigate(["/login?role=renter"].join(""));
                            }}
                        >
                            {t("common.signin")}
                        </PrimaryButton>
                    </Col>
                </Row>
            </ModalLayout>
            {selectedAgreementTitle && selectedAgreementSrc && (
                <AgreementReadModal
                    show={showReadAgreementModal}
                    title={selectedAgreementTitle}
                    agreementSrc={selectedAgreementSrc}
                    onCloseClick={() => {
                        setShowReadAgreementModal(false);
                    }}
                    onButtonClick={() => {
                        form.setFieldsValue({ [selectedAgreementTitle]: true });
                        setShowReadAgreementModal(false);
                    }}
                />
            )}
            <ModalLayout
                show={showRequestSuccessModal}
                onCloseClick={() => {
                    setShowRequestSuccessModal(false);
                }}
            >
                <Row wrap={true} justify="center" align="middle">
                    <Col md={16} xs={16}>
                        <h2 style={{ textAlign: "center" }}>{t("request.create.success.modal.title")}</h2>
                    </Col>
                    <Col md={24} xs={24}>
                        <p style={{ textAlign: "center" }}>{t("request.create.success.modal.text")}</p>
                    </Col>
                    <Col md={24} xs={24}>
                        <PrimaryButton
                            onClick={() => {
                                window.location.replace(activeRootSiteUrl);
                            }}
                        >
                            {t("request.create.success.modal.button.title")}
                        </PrimaryButton>
                    </Col>
                </Row>
            </ModalLayout>
            {
                <ModalLayout
                    show={showRedirectModal}
                    onCloseClick={() => {
                        setShowRedirectModal(false);
                    }}
                >
                    <Row gutter={[16, 16]} wrap={true} justify="center" align="middle">
                        <Col md={16} xs={16}>
                            <h3 style={{ textAlign: "center" }}>{t("request.create.redirect.modal.title")}</h3>
                        </Col>
                        <Col md={16} xs={16} style={{ display: "flex", alignItems: "center" }}>
                            <PrimaryButton
                                onClick={() => {
                                    if (machineAllDetail){
                                        const filteredUrl = getMachineSubcategoryFilteredLandingUrl(machineAllDetail);
                                        window.open(filteredUrl, "_self");
                                    }

                                }}
                                style={{ width: "300px" }}
                            >
                                {t("common.ok")}
                            </PrimaryButton>
                        </Col>
                    </Row>
                </ModalLayout>
            }
            </div>
            </>)}
        </OnboardLayout>
    );
};

export default AnonymRequest;