/* tslint:disable */
/* eslint-disable */
/**
 * MachineGo P1 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AuthType,
    AuthTypeFromJSON,
    AuthTypeFromJSONTyped,
    AuthTypeToJSON,
} from './AuthType';
import {
    DeviceType,
    DeviceTypeFromJSON,
    DeviceTypeFromJSONTyped,
    DeviceTypeToJSON,
} from './DeviceType';
import {
    SocialAuthPageType,
    SocialAuthPageTypeFromJSON,
    SocialAuthPageTypeFromJSONTyped,
    SocialAuthPageTypeToJSON,
} from './SocialAuthPageType';

/**
 * 
 * @export
 * @interface SocialLoginRequestModel
 */
export interface SocialLoginRequestModel {
    /**
     * 
     * @type {AuthType}
     * @memberof SocialLoginRequestModel
     */
    authType?: AuthType;
    /**
     * 
     * @type {SocialAuthPageType}
     * @memberof SocialLoginRequestModel
     */
    socialAuthPageType?: SocialAuthPageType;
    /**
     * 
     * @type {DeviceType}
     * @memberof SocialLoginRequestModel
     */
    deviceType?: DeviceType;
    /**
     * 
     * @type {string}
     * @memberof SocialLoginRequestModel
     */
    socialToken?: string | null;
}

export function SocialLoginRequestModelFromJSON(json: any): SocialLoginRequestModel {
    return SocialLoginRequestModelFromJSONTyped(json, false);
}

export function SocialLoginRequestModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): SocialLoginRequestModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'authType': !exists(json, 'authType') ? undefined : AuthTypeFromJSON(json['authType']),
        'socialAuthPageType': !exists(json, 'socialAuthPageType') ? undefined : SocialAuthPageTypeFromJSON(json['socialAuthPageType']),
        'deviceType': !exists(json, 'deviceType') ? undefined : DeviceTypeFromJSON(json['deviceType']),
        'socialToken': !exists(json, 'socialToken') ? undefined : json['socialToken'],
    };
}

export function SocialLoginRequestModelToJSON(value?: SocialLoginRequestModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'authType': AuthTypeToJSON(value.authType),
        'socialAuthPageType': SocialAuthPageTypeToJSON(value.socialAuthPageType),
        'deviceType': DeviceTypeToJSON(value.deviceType),
        'socialToken': value.socialToken,
    };
}

