import { MachineDetailModel } from "../service";
import { activeRootSiteUrl } from "../store/app-constants";
import { t } from "../translate";

export type MustFieldNameAndUnitType = {
    mustFieldLabel: string;
    mustFieldName: string;
    mustFieldUnit: string;
    mustFieldValue: string;
    noMustField?: boolean; // if there is no must field, that's true
  };
  export const get2SaleMachineMustField = (data: MachineDetailModel): MustFieldNameAndUnitType[] => {
    // Hafriyat Grubu
    if (data.subCategory?.category?.id === 1) {
      // Ekskavatörler (Kazici)	/ Mini Ekskavatörler /	Loder	/ Beko Loder / Mini Kazıcı - Yükleyici
      if (data.subCategory.id === 1 || data.subCategory.id === 2 || data.subCategory.id === 3 || data.subCategory.id === 4 || data.subCategory.id === 5) {
        return [
          {
            mustFieldLabel: t("must-fields.operatingWeight"),
            mustFieldName: "operatingWeight",
            mustFieldUnit: t("units.tons"),
            mustFieldValue: Number(data.operatingWeight).toLocaleString(),
          },
          {
            mustFieldLabel: t("must-fields.drivingEquipment"),
            mustFieldName: "drivingEquipment",
            mustFieldUnit: "",
            mustFieldValue: data.drivingEquipment ?? "",
          },
        ];
      }
      // Mini Belden Kırmalı Yükleyici
      if (data.subCategory.id === 76) {
        return [
          {
            mustFieldLabel: t("must-fields.operatingWeight"),
            mustFieldName: "operatingWeight",
            mustFieldUnit: t("units.tons"),
            mustFieldValue: Number(data.operatingWeight).toLocaleString(),
          },
          {
            mustFieldLabel: "",
            mustFieldName: "",
            mustFieldUnit: "",
            mustFieldValue: "",
            noMustField: true,
          },
        ];  
      }
      // Çekili Skreyper / Motorlu Skreyper
      if (data.subCategory.id === 6 || data.subCategory.id === 7) {
        return [
          {
            mustFieldLabel: t("must-fields.tankCapacity"),
            mustFieldName: "tankCapacity",
            mustFieldUnit: "lt",
            mustFieldValue: Number(data.tankCapacity).toLocaleString(),
          },
          {
            mustFieldLabel: "",
            mustFieldName: "",
            mustFieldUnit: "",
            mustFieldValue: "",
            noMustField: true,
          },
        ];  
      }
      // Mini Yükleyiiciler
      if (data.subCategory.id === 8) {
        return [
          {
            mustFieldLabel: t("must-fields.drivingEquipment"),
            mustFieldName: "drivingEquipment",
            mustFieldUnit: "",
            mustFieldValue: data.drivingEquipment?.toLocaleString() ?? '',
          },
          {
            mustFieldLabel: t("must-fields.loadCapacity"),
            mustFieldName: "loadCapacity",
            mustFieldUnit: "",
            mustFieldValue: data.loadCapacity?.toLocaleString() ?? '',
          },
        ];  
      }
    } 

    // dozer
    if (data.subCategory?.category?.id === 2) {
      return [
        {
          mustFieldLabel: t("must-fields.operatingWeight"),
          mustFieldName: "operatingWeight",
          mustFieldUnit: t("units.tons"),
          mustFieldValue: Number(data.operatingWeight).toLocaleString(),
        },
        {
          mustFieldLabel: "",
          mustFieldName: "",
          mustFieldUnit: "",
          mustFieldValue: "",
          noMustField: true,
        },
      ];  
    }
    // greyder
    if (data.subCategory?.category?.id === 3) {
      return [
        {
          mustFieldLabel: t("must-fields.operatingWeight"),
          mustFieldName: "operatingWeight",
          mustFieldUnit: t("units.tons"),
          mustFieldValue: Number(data.operatingWeight).toLocaleString(),
        },
        {
          mustFieldLabel: "",
          mustFieldName: "",
          mustFieldUnit: "",
          mustFieldValue: "",
          noMustField: true,
        },
      ];  
    }
    // kamyonlar
    if (data.subCategory?.category?.id === 4) {
      return [
        {
          mustFieldLabel: t("must-fields.operationMileage"),
          mustFieldName: "operationMileage",
          mustFieldUnit: "",
          mustFieldValue: Number(data.operationMileage).toLocaleString(),
        },
        {
          mustFieldLabel: "",
          mustFieldName: "",
          mustFieldUnit: "",
          mustFieldValue: "",
          noMustField: true,
        },
      ];  
    }
    // beton grubu
    if (data.subCategory?.category?.id === 5) {
      // Beton Transmikseri
      if (data.subCategory.id === 20) {
        return [
          {
            mustFieldLabel: t("must-fields.nominalCapacity"),
            mustFieldName: "nominalCapacity",
            mustFieldUnit: t("units.meter"),
            mustFieldValue: Number(data.nominalCapacity).toLocaleString(),
          },
          {
            mustFieldLabel: "",
            mustFieldName: "",
            mustFieldUnit: "",
            mustFieldValue: "",
            noMustField: true,
          },
        ];  
      }
      // Mobil Beton Pompası / Sabit Beton Pompası
      else {
        return [
          {
            mustFieldLabel: t("must-fields.maxHeight"),
            mustFieldName: "maxHeight",
            mustFieldUnit: t("units.meter"),
            mustFieldValue: Number(data.maxHeight).toLocaleString(),
          },
          {
            mustFieldLabel: "",
            mustFieldName: "",
            mustFieldUnit: "",
            mustFieldValue: "",
            noMustField: true,
          },
        ];  
      }
    }
    // vinçler
    if (data.subCategory?.category?.id === 6) {
      return [
        {
          mustFieldLabel: t("must-fields.operatingWeight"),
          mustFieldName: "operatingWeight",
          mustFieldUnit: "",
          mustFieldValue: Number(data.operatingWeight).toLocaleString(),
        },
        {
          mustFieldLabel: "",
          mustFieldName: "",
          mustFieldUnit: "",
          mustFieldValue: "",
          noMustField: true,
        },
      ];  
    } 
    // Temizlik Makineleri
    if (data.subCategory?.category?.id === 7) {
      return [
        {
          mustFieldLabel: t("must-fields.operationHour"),
          mustFieldName: "operationHour",
          mustFieldUnit: t("units.hour"),
          mustFieldValue: Number(data.operationHour).toLocaleString(),
        },
        {
          mustFieldLabel: "",
          mustFieldName: "",
          mustFieldUnit: "",
          mustFieldValue: "",
          noMustField: true,
        },
      ];  
    } 
    // Kompresörler
    if (data.subCategory?.category?.id === 8) {
      return [
        {
          mustFieldLabel: t("must-fields.enginePower"),
          mustFieldName: "enginePower",
          mustFieldUnit: "hp",
          mustFieldValue: Number(data.enginePower).toLocaleString(),
        },
        {
          mustFieldLabel: "",
          mustFieldName: "",
          mustFieldUnit: "",
          mustFieldValue: "",
          noMustField: true,
        },
      ];  
    } 
    // Forklift ve İstifleyiciler
    if (data.subCategory?.category?.id === 9) {
      if ([36, 39, 40, 42].includes(data.subCategory.id ?? -1)) {
        return [
          {
            mustFieldLabel: t("must-fields.carryingCapacity"),
            mustFieldName: "carryingCapacity",
            mustFieldUnit: t("common.ton"),
            mustFieldValue: Number(data.carryingCapacity).toLocaleString(),
          },
          {
            mustFieldLabel: "",
            mustFieldName: "",
            mustFieldUnit: "",
            mustFieldValue: "",
            noMustField: true,
          },
        ];  
      }
      if ([37].includes(data.subCategory.id ?? -1)) {
        return [
          {
            mustFieldLabel: t("must-fields.carryingCapacity"),
            mustFieldName: "carryingCapacity",
            mustFieldUnit: t("common.ton"),
            mustFieldValue: Number(data.carryingCapacity).toLocaleString(),
          },
          {
            mustFieldLabel: t("must-fields.fuelType"),
            mustFieldName: "fuelType",
            mustFieldUnit: "",
            mustFieldValue: data.fuelType?.name ?? "",
          },
        ];  
      }
      if ([38, 41].includes(data.subCategory.id ?? -1)) {
        return [
          {
            mustFieldLabel: t("must-fields.carryingCapacity"),
            mustFieldName: "carryingCapacity",
            mustFieldUnit: t("common.ton"),
            mustFieldValue: Number(data.carryingCapacity).toLocaleString(),
          },
          {
            mustFieldLabel: t("must-fields.liftingHeight"),
            mustFieldName: "liftingHeight",
            mustFieldUnit: "",
            mustFieldValue: data.liftingHeight?.toString() ?? "",
          },
        ];  
      }
      return [
        {
          mustFieldLabel: t("must-fields.carryingCapacity"),
          mustFieldName: "carryingCapacity",
          mustFieldUnit: t("common.ton"),
          mustFieldValue: Number(data.carryingCapacity).toLocaleString(),
        },
        {
          mustFieldLabel: "",
          mustFieldName: "",
          mustFieldUnit: "",
          mustFieldValue: "",
          noMustField: true,
        },
      ];  
    } 
    // Manlift (Personel Yükselticiler) / Platformlar
    if (data.subCategory?.category?.id === 10) {  
      // Eklemli Platformlar / Makaslı Platform
      if (data.subCategory.id === 43 || data.subCategory.id === 48) {
        return [
          {
            mustFieldLabel: t("must-fields.fuelType"),
            mustFieldName: "fuelType",
            mustFieldUnit: "",
            mustFieldValue: data.fuelType?.name ?? "",
          },
          {
            mustFieldLabel: t("must-fields.operatingHeight"),
            mustFieldName: "operatingHeight",
            mustFieldUnit: "",
            mustFieldValue: data.operatingHeight?.toString() ?? "",
          },
        ];  
      }
      return [
        {
          mustFieldLabel: t("must-fields.operatingHeight"),
          mustFieldName: "operatingHeight",
          mustFieldUnit: "",
          mustFieldValue: data.operatingHeight?.toString() ?? "",
        },
        {
          mustFieldLabel: "",
          mustFieldName: "",
          mustFieldUnit: "",
          mustFieldValue: "",
          noMustField: true,
        },
      ];
    } 
    // Jeneratör / Aydınlatma
    if (data.subCategory?.category?.id === 11) {  
      return [
        {
          mustFieldLabel: t('must-field.maxPower'),
          mustFieldName: "maxPower",
          mustFieldUnit: "",
          mustFieldValue: data.maxPower?.toString() ?? "",
        },
        {
          mustFieldLabel: "",
          mustFieldName: "",
          mustFieldUnit: "",
          mustFieldValue: "",
          noMustField: true,
        },
      ];
    } 
    // Asfalt ve Yol Makinalari
    if (data.subCategory?.category?.id === 12) {  
      if ([54, 55, 56, 75, 77, 62].includes(data.subCategory.id ?? -1)) {
        return [
          {
            mustFieldLabel: t("must-fields.operatingWeight"),
            mustFieldName: "operatingWeight",
            mustFieldUnit: "",
            mustFieldValue: data.operatingWeight?.toString() ?? "",
          },
          {
            mustFieldLabel: "",
            mustFieldName: "",
            mustFieldUnit: "",
            mustFieldValue: "",
            noMustField: true,
          },
        ]; 
      }
      if ([60].includes(data.subCategory.id ?? -1)) {
        return [
          {
            mustFieldLabel: t("must-fields.spraySpeed"),
            mustFieldName: "spraySpeed",
            mustFieldUnit: "",
            mustFieldValue: data.operatingWeight?.toString() ?? "",
          },
          {
            mustFieldLabel: "",
            mustFieldName: "",
            mustFieldUnit: "",
            mustFieldValue: "",
            noMustField: true,
          },
        ]; 
      }
      if ([61].includes(data.subCategory.id ?? -1)) {
        return [
          {
            mustFieldLabel: t("must-fields.tankVolume"),
            mustFieldName: "tankVolume",
            mustFieldUnit: "",
            mustFieldValue: data.tankVolume?.toString() ?? "",
          },
          {
            mustFieldLabel: "",
            mustFieldName: "",
            mustFieldUnit: "",
            mustFieldValue: "",
            noMustField: true,
          },
        ]; 
      }
      if ([63].includes(data.subCategory.id ?? -1)) {
        return [
          {
            mustFieldLabel: t("must-fields.loadCapacity"),
            mustFieldName: "loadCapacity",
            mustFieldUnit: "",
            mustFieldValue: data.loadCapacity?.toLocaleString() ?? '',
          },
          {
            mustFieldLabel: "",
            mustFieldName: "",
            mustFieldUnit: "",
            mustFieldValue: "",
            noMustField: true,
          },
        ]; 
      }
      return [
        {
          mustFieldLabel: "",
          mustFieldName: "",
          mustFieldUnit: "",
          mustFieldValue: "",
          noMustField: true,
        },
        {
          mustFieldLabel: "",
          mustFieldName: "",
          mustFieldUnit: "",
          mustFieldValue: "",
          noMustField: true,
        },
      ];
    } 
    // Nakliye Makinalari
    if (data.subCategory?.category?.id === 13) {  
      return [
        {
          mustFieldLabel: t("must-fields.operationMileage"),
          mustFieldName: "operationMileage",
          mustFieldUnit: "",
          mustFieldValue: Number(data.operationMileage).toLocaleString(),
        },
        {
          mustFieldLabel: "",
          mustFieldName: "",
          mustFieldUnit: "",
          mustFieldValue: "",
          noMustField: true,
        },
      ];  
    } 
    // Konteyner
    if (data.subCategory?.category?.id === 14) {  
      return [
        {
          mustFieldLabel: t("must-fields.volume"),
          mustFieldName: "volume",
          mustFieldUnit: "",
          mustFieldValue: data.volume?.toString() ?? "",
        },
        {
          mustFieldLabel: "",
          mustFieldName: "",
          mustFieldUnit: "",
          mustFieldValue: "",
          noMustField: true,
        },
      ]; 
    } 
    // Pick-up SUV
    if (data.subCategory?.category?.id === 15) {  
      return [
        {
          mustFieldLabel: t("must-fields.operationMileage"),
          mustFieldName: "operationMileage",
          mustFieldUnit: "",
          mustFieldValue: Number(data.operationMileage).toLocaleString(),
        },
        {
          mustFieldLabel: t("must-fields.enginePower"),
          mustFieldName: "enginePower",
          mustFieldUnit: t("units.hoursePower"),
          mustFieldValue: Number(data.enginePower).toLocaleString(),
        },
      ];
    } 
    // Diğer
    if (data.subCategory?.category?.id === 16) {  
      // drone
      if (data.subCategory.id === 73) {
        return [
          {
            mustFieldLabel: t("must-fields.flightTime"),
            mustFieldName: "flightTime",
            mustFieldUnit: t("units.hour"),
            mustFieldValue: Number(data.flightTime).toLocaleString(),
          },
          {
            mustFieldLabel: "",
            mustFieldName: "",
            mustFieldUnit: "",
            mustFieldValue: "",
            noMustField: true,
          },
        ];
      }
      return [
        {
          mustFieldLabel: "",
          mustFieldName: "",
          mustFieldUnit: "",
          mustFieldValue: "",
          noMustField: true,
        },
        {
          mustFieldLabel: "",
          mustFieldName: "",
          mustFieldUnit: "",
          mustFieldValue: "",
          noMustField: true,
        },
      ];
    } 
    // Telehandler
    if (data.subCategory?.category?.id === 17) {  
      return [
        {
          mustFieldLabel: t("must-fields.operatingWeight"),
          mustFieldName: "operatingWeight",
          mustFieldUnit: t("units.tons"),
          mustFieldValue: Number(data.operatingWeight).toLocaleString(),
        },
        {
          mustFieldLabel: "",
          mustFieldName: "",
          mustFieldUnit: "",
          mustFieldValue: "",
          noMustField: true,
        },
      ];  
    } 
    return [
      {
        mustFieldLabel: "",
        mustFieldName: "",
        mustFieldUnit: "",
        mustFieldValue: "",
        noMustField: true,
      },
      {
        mustFieldLabel: "",
        mustFieldName: "",
        mustFieldUnit: "",
        mustFieldValue: "",
        noMustField: true,
      },
    ];
  };
  

export const getMachineSubcategoryFilteredLandingUrl = (machine: MachineDetailModel) => {
    const turkishToEnglish: Record<string, string> = {
      ç: "c",
      ğ: "g",
      ı: "i",
      ö: "o",
      ş: "s",
      ü: "u",
      Ç: "C",
      Ğ: "G",
      İ: "I",
      Ö: "O",
      Ş: "S",
      Ü: "U",
    };
  
    const filteredMachineUrl: string = `${activeRootSiteUrl}/kiralik-is-makineleri/${machine.subCategory?.name?.trim().replace(/\s+/g, "-")
        .replace(/[çğıöşüÇĞİÖŞÜ]/g, (letter) => turkishToEnglish[letter])
        .replace(/[^a-z0-9-/]/gi, "")
        .replace(/-+/g, "-")
        .toLowerCase()}`
  
  
    return filteredMachineUrl;
};