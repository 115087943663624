import { Col, Form, Row } from "antd";
import PrimaryButton from "../../components/atomics/primary-button";
import UsernameInput from "../../components/atomics/username-input";
import AuthLayout from "../../components/layouts/auth-layout";
import { t } from "../../translate";
import { useNavigate } from "react-router-dom";
import { useQuery } from "../../helpers/use-query";
import { useSecureService } from "../../store/service-atoms";
import { useAtom } from "jotai";
import { RoleType, loadingAtom } from "../../store/global-atoms";
import styles from "./forgot-password.module.scss"

const ForgotPassword = () => {
    const query = useQuery();
    const role = query.get("role") as RoleType || "customer";
    const navigate = useNavigate();
    const { doForgotPassword } = useSecureService();
    const [loading] = useAtom(loadingAtom);

    const formFinishHandler = async (params: { phone: string }) => {
        params.phone= params.phone.replace("+90", "");
        params.phone = params.phone.replace(/\s+/g, "");
        const unmaskedPhoneValue = params.phone.replace(/\D/g, ""); 
        const result = await doForgotPassword(role, unmaskedPhoneValue);
        if (result) navigate("/sms-verification?role=" + role + "&id=" + result + "&phone=" + unmaskedPhoneValue + "&op=forgot-password");
    };

    return (
        <AuthLayout hideBackgroundImg hideMachineLogo hideUpperLogo>
            <Row className={styles.container}>
                <Col xs={24}>
                    <Row justify="center">
                        <h1>{t('forgot-password.title')}</h1>
                    </Row>
                    <Row justify="center">
                        <p>{t('forgot-password.description')}</p>
                    </Row>
                </Col>
                <Col xs={24}>
                    <Form name="normal_login" className="login-form" layout="vertical" onFinish={formFinishHandler}>
                        <div>
                            <UsernameInput type="phone" prefix />
                        </div>
                        <div>
                            <PrimaryButton loading={loading} htmlType="submit">
                                {t("common.continue")}
                            </PrimaryButton>
                        </div>
                    </Form>       
                </Col>
            </Row>
        </AuthLayout>
    );
};
export default ForgotPassword;
