import { DeviceType } from "../service";

const getDeviceType = (): DeviceType => {
    const userAgent = navigator.userAgent;

    if (/mobile/i.test(userAgent)) {
        return DeviceType.Mobile;
    } else if (/tablet/i.test(userAgent) || /iPad|Android|Touch/i.test(userAgent)) {
        return DeviceType.Tablet;
    } else if (/Windows|Macintosh|Linux/i.test(userAgent)) {
        return DeviceType.Desktop;
    } else {
        return DeviceType.Other;
    }
};


export default getDeviceType;